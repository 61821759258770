import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';

import ReusableModal from '../components/editor_components/ReusableModal/ReusableModal';
import Notification from '../components/Notifications/Notifications';
import ConfirmationModal from '../components/ConfirmModal/ConfirmModal';

import useDarkMode from '../hooks/useDarkMode';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { IconMap } from '../config/IconMap';

export default function ProductEditor({ context }) {
    const { uid } = useParams();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);
    const [isPublishing, setIsPublishing] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [showNotification, setShowNotification] = useState(false);

    const [error, setError] = useState(null);
    const [product, setProduct] = useState(null);
    const [images, setImages] = useState([]);
    const [productName, setProductName] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState('');
    const [discount, setDiscount] = useState('');
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [isCategoryModalOpen, setCategoryModalOpen] = useState(false);
    const [isCreatingNewCategory, setIsCreatingNewCategory] = useState(true);
    const [newCategory, setNewCategory] = useState({ title: '', description: '' });
    const [generalError, setGeneralError] = useState("");
    const [initialCategories, setInitialCategories] = useState([]);
    const [removedPositions, setRemovedPositions] = useState([]);
    const [defaultVariant, setDefaultVariant] = useState(null);

    const [fieldValues, setFieldValues] = useState([]);
    const [variants, setVariants] = useState([]);
    const [currentVariant, setCurrentVariant] = useState(null);
    const [isVariantModalOpen, setIsVariantModalOpen] = useState(false);
    const [variantFieldValues, setVariantFieldValues] = useState({
        sku: '',
        price: '',
        tempId: '',
        attributes: [] // Add this
    });

    const [prevTitle, setPrevTitle] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [darkModeEnabled, handleToggleDarkMode] = useDarkMode();

    const triggerNotification = () => {
        setShowNotification(true);
        setTimeout(() => {
            setShowNotification(false);
        }, 3000);
    };

    // Fetch product data
    useEffect(() => {
        fetchData();
    }, [uid]);

    useEffect(() => {
        if (product?.title) {
            setPrevTitle(product.title);
        }
    }, [product]); // Store previous title
    
    // Fetch data 
    const fetchData = async () => {
        try {
            setIsLoading(true);
            const token = Cookies.get('auth_token');
    
            // Fetch product details
            const productResponse = await axios.get(`/api/product/${uid}`, {
                headers: { Authorization: `Token ${token}` },
            });
            const productData = productResponse.data;
    
            // Ensure each variant has a tempId
            const formattedVariants = productData.variants.map(variant => ({
                ...variant,
                tempId: `temp-${variant.sku || Date.now()}`,
            }));
    
            // Fetch all categories
            const categoryResponse = await axios.get('/api/categories', {
                headers: { Authorization: `Token ${token}` },
            });
            const allCategories = categoryResponse.data.categories;
    
            //  Identify the actual default variant
            const defaultVar = formattedVariants.find(v => v.is_default) || null;
    
            setProduct(productData);
            setProductName(productData.title);
            setDescription(productData.description);
            setPrice(productData.price);
            setDiscount(productData.discount);
            setImages(productData.images || []);
            setCategories(allCategories);
            setSelectedCategories(
                allCategories.filter(cat => productData.categories.includes(cat.title)).map(cat => cat.id)
            );
            setVariants(formattedVariants);
            setDefaultVariant(defaultVar); //  Store the default variant correctly
            setHasUnsavedChanges(false);
    
        } catch (error) {
            setError(error);
        } finally {
            setIsLoading(false);
        }
    };    

    const handleFieldChange = (setter) => (e) => {
        setter(e.target.value);
        setHasUnsavedChanges(true);
    };

    // Function to fetch categories from API
    const fetchCategories = async () => {
        const token = Cookies.get('auth_token');
        try {
            const response = await axios.get('/api/categories', {
                headers: { Authorization: `Token ${token}` },
            });
            setCategories(response.data.categories); // Access the categories array within the response data
        } catch (error) {
            setGeneralError("Failed to load categories.");
        }
    };

    // Toggle between creating a new category and selecting existing ones
    const toggleCategoryModalView = () => {
        setIsCreatingNewCategory((prev) => !prev); // Toggle the creation mode
    };

    // Open category modal in "Select Existing" mode
    const openCategoryModal = () => {
        fetchCategories();
        setIsCreatingNewCategory(false);
        setCategoryModalOpen(true);
        setFieldValues(selectedCategories); // Ensure fieldValues is an array of selected category IDs
    };

    const openVariantModal = (variant = null) => {
        if (variant) {
            setCurrentVariant(variant);
            setVariantFieldValues({
                sku: variant.sku,
                price: variant.price,
                tempId: variant.tempId || `temp-${Date.now()}-${Math.random().toString(36).substr(2, 5)}`,
                attributes: variant.attributes || [],
                is_default: variant.is_default || false //  Ensure `is_default` is passed
            });
        } else {
            setCurrentVariant(null);
            setVariantFieldValues({
                sku: '',
                price: '',
                tempId: `temp-${Date.now()}-${Math.random().toString(36).substr(2, 5)}`,
                attributes: [],
                is_default: false //  Default to false for new variants
            });
        }
        setIsVariantModalOpen(true);
    };

    const handleAddAttribute = () => {
        setVariantFieldValues(prev => ({
            ...prev,
            attributes: [...prev.attributes, { title: '', value: '' }]
        }));
    };

    const handleAttributeChange = (index, field, value) => {
        setVariantFieldValues(prev => {
            const newAttributes = [...prev.attributes];
            newAttributes[index] = {
                ...newAttributes[index],
                [field]: value
            };
            return {
                ...prev,
                attributes: newAttributes
            };
        });
    };

    const handleRemoveAttribute = (index) => {
        setVariantFieldValues(prev => ({
            ...prev,
            attributes: prev.attributes.filter((_, i) => i !== index)
        }));
    };

    // Save or Select Category based on modal state
    const handleSaveCategory = async (newCategory) => {
        const token = Cookies.get('auth_token');
        try {
            const response = await axios.post('/api/categories', newCategory, {
                headers: { Authorization: `Token ${token}` },
            });
            setCategories((prev) => [...prev, response.data]);
            setSelectedCategories((prev) => [...prev, response.data.id]);
            setCategoryModalOpen(false);
            setNewCategory({ title: '', description: '' });
            setHasUnsavedChanges(true);
        } catch (error) {
            if (error.response && error.response.status === 400) {
                // Display inline validation error for duplicate title
                setGeneralError(error.response.data.title || 'Failed to save category');
            } else {
                setGeneralError("An unexpected error occurred.");
            }
        }
    };

    // Toggle existing category selection
    const toggleCategorySelection = (categoryId) => {
        setSelectedCategories((prevSelected) =>
            prevSelected.includes(categoryId)
                ? prevSelected.filter((id) => id !== categoryId)
                : [...prevSelected, categoryId]
        );
        setHasUnsavedChanges(true);
    };

    // Handle save operation for the variant modal
    const handleSaveVariant = () => {
        // Validate fields
        if (!variantFieldValues.price) {
            return;
        }
        setVariants(prev => {
            const newVariant = {
                ...variantFieldValues,
                tempId: variantFieldValues.tempId || Date.now().toString()
            };

            if (currentVariant) {
                // Update existing
                return prev.map(v => v.tempId === currentVariant.tempId ? newVariant : v);
            } else {
                // Add new
                return [...prev, newVariant];
            }
        });
        setIsVariantModalOpen(false);
        setCurrentVariant(null);
        setHasUnsavedChanges(true);
    };

    // Handle image upload with preview and 3-image limit
    const handleImageUpload = (event) => {
        const files = Array.from(event.target.files);

        // Prevent exceeding 3 images
        if (images.length + files.length > 3) {
            alert("You can only upload up to 3 images.");
            return;
        }

        const newImages = files.map((file) => ({
            file,
            previewUrl: URL.createObjectURL(file),
        }));

        setImages((prevImages) => [...prevImages, ...newImages].slice(0, 3));
        setHasUnsavedChanges(true);
    };

    // Handle image removal
    const handleRemoveImage = (index) => {
        setImages((prevImages) => prevImages.filter((_, i) => i !== index));
        setRemovedPositions((prev) => [...prev, index + 1]);
        setHasUnsavedChanges(true);
    };

    const handleAddVariant = () => {
        openVariantModal();
    };

    const handleSaveProduct = async () => {
        setIsPublishing(true); // ✅ Don't reset isLoading
    
        const token = Cookies.get('auth_token');
        const formData = new FormData();
    
        formData.append("title", productName);
        formData.append("description", description);
        selectedCategories.forEach((categoryId) => {
            formData.append("categories", categoryId);
        });
    
        images.forEach((image) => {
            if (image.file) {
                formData.append("images", image.file);
            }
        });
    
        formData.append("removed_positions", JSON.stringify(removedPositions));
    
        const updatedVariants = variants.map(variant => ({
            ...variant,
            tempId: variant.tempId || `temp-${Date.now()}-${Math.random().toString(36).substr(2, 5)}`
        }));
    
        if (updatedVariants.length > 0) {
            formData.append("variants", JSON.stringify(updatedVariants));
        }
    
        setRemovedPositions([]);
    
        try {
            const response = await axios.post(`/api/update-product/${uid}/`, formData, {
                headers: {
                    Authorization: `Token ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });
    
            if (response.status === 200) {
                setProduct(response.data); // ✅ Keep product title visible
                setHasUnsavedChanges(false);
                setError(null);
                triggerNotification();
            } else {
                throw new Error('Failed to save product.');
            }
    
        } catch (error) {
            console.error('Save error:', error);
            setError('Failed to save product');
        } finally {
            setIsPublishing(false); // ✅ Don't reload
        }
    };    

    const handleDeleteVariant = (tempId) => {
        const variantToDelete = variants.find(v => v.tempId === tempId);
        if (!variantToDelete) {
            console.error("Variant not found");
            return;
        }
        if (defaultVariant && variantToDelete.sku === defaultVariant.sku) {
            alert("Cannot delete the default variant.");
            return;
        }
        setVariants(prevVariants => prevVariants.filter(v => v.tempId !== tempId));
        setHasUnsavedChanges(true);
    };

    const handleDeleteClick = (itemId) => {
        setSelectedItemId(itemId);
        setIsModalOpen(true);
    };

    const handleDeleteProduct = async () => {
        setIsModalOpen(false);
        setIsDeleting(true);
        const token = Cookies.get('auth_token');
        try {
            await axios.delete(`/api/delete-product/${uid}`, {
                headers: { Authorization: `Token ${token}` },
            });
            navigate("/m/products");
        } catch (error) {
            console.error("Failed to delete product:", error);
        } finally {
            setIsDeleting(false);
        }
    };

    return (
        <>
            <main className="lg:pl-72">
                <div className="xl:pr-96 px-4 py-4 lg:px-8 lg:py-8">
                    <nav aria-label="Breadcrumb">
                        <ol role="list" className="flex items-center space-x-2">
                            <li>
                                <a href="/m/products" className="text-gray-400 hover:text-gray-500">
                                    <span className="text-sm font-medium text-gray-500 hover:text-gray-700 dark:text-white/75 dark:text-white/75 dark:hover:text-white">Products</span>
                                </a>
                            </li>
                            <li>
                                <ChevronRightIcon className="h-5 w-5 text-gray-400" />
                            </li>
                            <li>
                                <a href="#" className="text-sm font-medium text-gray-500 dark:text-white hover:text-gray-700 dark:text-white/75">
                                    {isLoading ? "Loading" : prevTitle}
                                </a>
                            </li>
                        </ol>
                    </nav>

                    <div className="mt-4 bg-white dark:bg-zinc-800 border dark:border-white/10 p-4 lg:p-6 rounded-lg">
                        {isLoading ? (
                            <h1 className="text-base font-semibold text-gray-900 dark:text-white/75">Loading...</h1>
                        ) : error ? (
                            <h1 className="text-base font-semibold text-red-600">Error loading product</h1>
                        ) : (
                            <>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 dark:text-white/75">Product title</label>
                                        <input
                                            type="text"
                                            value={productName}
                                            onChange={handleFieldChange(setProductName)}
                                            className="mt-2 block w-full p-2 border border-gray-300 rounded-md dark:bg-zinc-900 dark:text-white/75 dark:ring-1 dark:ring-white/10 dark:border-0"
                                            placeholder="Enter product name"
                                        />
                                        <label className="mt-4 block text-sm font-medium text-gray-700 dark:text-white/75">Description</label>
                                        <textarea
                                            value={description}
                                            onChange={handleFieldChange(setDescription)}
                                            className="mt-2 block w-full p-2 border border-gray-300 rounded-md dark:bg-zinc-900 dark:text-white/75 dark:ring-1 dark:ring-white/10 dark:border-0"
                                            placeholder="Enter product description"
                                            rows={4}
                                        />

                                        <label className="mt-8 block text-sm font-medium text-gray-700 dark:text-white/75">Selected categories</label>
                                        <div className={`flex flex-wrap gap-2 mt-2`}>
                                            {selectedCategories.map((id) => {
                                                const category = categories.find((cat) => cat.id === id);
                                                return (
                                                    <span key={id} className="inline-flex items-center gap-x-0.5 rounded-md bg-indigo-100 dark:bg-zinc-800 dark:ring-1 dark:ring-indigo-300 px-2 py-1 text-xs font-medium text-indigo-800 dark:text-indigo-300">
                                                        {category?.title}
                                                        <button
                                                            type="button"
                                                            onClick={() => toggleCategorySelection(id)}
                                                            className="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-indigo-600/20"
                                                        >
                                                            <svg viewBox="0 0 14 14" className="h-3.5 w-3.5 stroke-indigo-800/50 group-hover:stroke-indigo-800/75 dark:stroke-indigo-300 dark:group-hover:stroke-white/75">
                                                                <path d="M4 4l6 6m0-6l-6 6" />
                                                            </svg>
                                                        </button>
                                                    </span>
                                                );
                                            })}
                                        </div>
                                        <button
                                            onClick={openCategoryModal}
                                            className={`${selectedCategories.length > 0 ? 'mt-2' : ''} rounded-md bg-white dark:bg-zinc-800 dark:text-white/75 dark:hover:bg-zinc-900 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50`}
                                        >
                                            Manage categories
                                        </button>

                                        <h3 className="mt-8 text-sm font-medium text-gray-700 dark:text-white/75">Product variations</h3>
                                        {variants.length > 0 ? (
                                            <ul>
                                                {variants.map((variant) => (
                                                    <li key={variant.tempId} className="flex flex-col md:flex-row justify-between items-start md:items-center p-4 border dark:border-0 dark:ring-1 dark:ring-white/10 rounded-md my-2 dark:bg-zinc-900">
                                                        <div className="flex flex-wrap gap-x-6 gap-y-2 md:flex-nowrap">
                                                            <p className="text-sm font-medium text-gray-900 dark:text-white/75">Price: {variant.price}</p>
                                                            {!variant.is_default && (
                                                                <p className="text-sm font-medium text-gray-900 dark:text-white/75">
                                                                    SKU: {variant.sku}
                                                                </p>
                                                            )}                                                        
                                                        </div>
                                                        {variant.attributes?.map(attr =>
                                                            <div className="mt-2 md:mt-0 text-sm text-gray-600 dark:text-white/75">
                                                                {attr.title}
                                                            </div>
                                                        )}
                                                        <div className="flex gap-4 mt-3 md:mt-0">
                                                            <button
                                                                className="font-semibold text-indigo-600 hover:text-indigo-900 dark:text-indigo-300 dark:hover:text-indigo-500"
                                                                onClick={() => openVariantModal(variant)}>
                                                                    Update
                                                            </button>
                                                            {!variant.is_default && (
                                                                <button
                                                                    className="font-semibold text-red-600 hover:text-red-900 dark:text-red-300 dark:hover:text-red-500"
                                                                    onClick={() => handleDeleteVariant(variant.tempId)}>
                                                                        Delete
                                                                </button>
                                                            )}
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        ) : (
                                            <p className="text-sm text-gray-500">No variations set.</p>
                                        )}

                                        <button className="mt-2 rounded-md bg-white dark:bg-zinc-800 dark:text-white/75 dark:hover:bg-zinc-900 px-2 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" onClick={handleAddVariant}>Create variant</button>
                                    </div>

                                    <div>
                                        <div className="mt-2 justify-center rounded-lg border bg-gray-50 dark:bg-zinc-900 dark:border-0 dark:ring-1 dark:ring-white/10 px-4 py-4 text-center">
                                            <div className="grid grid-cols-3 gap-4">

                                                {images.map((image, index) => (
                                                    <div key={index} className="relative">
                                                        <img src={image.previewUrl || image.image_url} alt={`Product ${index}`} className="w-full h-32 object-cover rounded-md" />
                                                        <button
                                                            type="button"
                                                            onClick={() => handleRemoveImage(index)}
                                                            className="absolute top-2 left-2 text-white font-bold text-2xl rounded-full h-8 w-8 flex items-center justify-center bg-gray-700"
                                                        >
                                                            {React.createElement(IconMap['XMarkIcon'], { className: 'h-5 w-5 text-white', 'aria-hidden': 'true' })}
                                                        </button>
                                                    </div>
                                                ))}

                                                {images.length > 0 && images.length < 3 && (
                                                    <div className="relative flex flex-col items-center justify-center w-full h-32 rounded-md border-2 border-dashed border-slate-300 hover:border-blue-500 hover:text-blue-500">
                                                        {React.createElement(IconMap['PlusIcon'], { className: 'h-8 w-8 text-gray-500', 'aria-hidden': 'true' })}
                                                        <input
                                                            type="file"
                                                            accept="image/*"
                                                            multiple
                                                            onChange={handleImageUpload}
                                                            className="absolute inset-0 opacity-0 cursor-pointer"
                                                        />
                                                    </div>
                                                )}

                                            </div>

                                            {images.length === 0 && (
                                                <div>
                                                    {React.createElement(IconMap['PhotoIcon'], { className: 'mx-auto h-12 w-12 text-gray-300', 'aria-hidden': 'true' })}
                                                    <label htmlFor="image-upload" className="relative underline cursor-pointer rounded-md bg-white dark:bg-zinc-900 font-semibold text-indigo-600 dark:text-indigo-300 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500">
                                                        Upload a file
                                                    </label>
                                                    <p className="text-xs leading-5 text-gray-600 dark:text-white/75">PNG, JPG, GIF up to 1MB</p>
                                                </div>
                                            )}

                                            <input
                                                type="file"
                                                accept="image/*"
                                                multiple
                                                id="image-upload"
                                                onChange={handleImageUpload}
                                                className="hidden"
                                            />
                                        </div>
                                    </div>

                                </div>

                                <button
                                    onClick={handleSaveProduct}
                                    className={`mt-4 px-4 py-2 rounded-md text-sm font-semibold ${isPublishing ? "bg-gray-400" : "bg-indigo-600 hover:bg-indigo-500 text-white"}`}
                                    disabled={isPublishing}
                                >
                                    {isPublishing ? "Publishing..." : "Publish"}
                                </button>

                                <button
                                    onClick={() => handleDeleteClick(uid)}
                                    className={`ml-4 px-4 py-2 rounded-md text-sm font-semibold text-white 
                                        ${isDeleting ? "bg-gray-400 cursor-not-allowed" : "bg-red-600 hover:bg-red-500"}`}
                                    disabled={isDeleting}  // Disable when deleting
                                >
                                    {isDeleting ? "Deleting..." : "Delete Product"}
                                </button>

                            </>
                        )}
                    </div>
                    <Notification
                        show={showNotification}
                        onClose={() => setShowNotification(false)}
                    />
                </div>
            </main>

            <ReusableModal
                isOpen={isCategoryModalOpen}
                closeModal={() => setCategoryModalOpen(false)}
                title="Manage categories"
                handleSave={handleSaveCategory}
                isCategoryModal={true}
                isCreatingNewCategory={isCreatingNewCategory}
                toggleMode={toggleCategoryModalView}
                fieldValues={isCreatingNewCategory ? newCategory : selectedCategories}
                setFieldValues={isCreatingNewCategory ? setNewCategory : setSelectedCategories}
                categories={categories}
                setCategories={setCategories} // ✅ Pass setCategories as a prop
            />

            <ReusableModal
                isOpen={isVariantModalOpen}
                closeModal={() => setIsVariantModalOpen(false)}
                title={currentVariant ? "Update product variant" : "Create product variant"}
                handleSave={() => handleSaveVariant()} // Ensure this is called
                isCategoryModal={false}
                fieldValues={variantFieldValues}
                setFieldValues={setVariantFieldValues}
            />

            <ConfirmationModal
                title="Delete Product"
                contextMsg="Are you sure you want to delete this product? This action cannot be undone."
                open={isModalOpen}
                onCancel={() => setIsModalOpen(false)}
                onConfirm={handleDeleteProduct}
            />
        </>
    );
}
