import React, { useState, useEffect } from 'react';
import { IconMap } from '../../config/IconMap';

const CustomToolbar = ({ onToggleTooltip, isTooltipVisible, previewNodeVisible }) => {
  const [position, setPosition] = useState(() => {
    const padding = 20;
    return { x: padding, y: window.innerHeight / 2 };
  });
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setDragStart({
      x: e.clientX - position.x,
      y: e.clientY - position.y
    });
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      const reactFlowBounds = document.querySelector('.react-flow')?.getBoundingClientRect();
      if (!reactFlowBounds) return;

      const toolbarElement = document.querySelector('.toolbar')?.getBoundingClientRect();
      if (!toolbarElement) return;

      const padding = 20;
      
      // Calculate new position within bounds
      const newX = Math.max(
        padding,
        Math.min(
          e.clientX - dragStart.x,
          reactFlowBounds.width - toolbarElement.width - padding
        )
      );
      
      const newY = Math.max(
        padding,
        Math.min(
          e.clientY - dragStart.y,
          reactFlowBounds.height - toolbarElement.height - padding
        )
      );

      setPosition({ x: newX, y: newY });
    }
  };

  useEffect(() => {
    const handleMouseUp = () => {
      setIsDragging(false);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, dragStart]);

  const nodeTypes = [
    { 
      type: 'action', 
      label: 'Navigation',
      schema: {
        id: `section-${Date.now()}`,
        display_title: 'Navigation',
        type: 'action',
        schema: []
      },
      iconClass: 'icon-nav' // Custom CSS class for the icon
    },
    { 
      type: 'gallery', 
      label: 'Gallery',
      schema: {
        id: `section-${Date.now()}`,
        display_title: 'Product Gallery',
        type: 'gallery',
        schema: []
      },
      iconClass: 'icon-product' // Custom CSS class for the icon
    }
  ];

  const onDragStart = (event, nodeType) => {
    const schemaData = {
        id: `section-${Date.now()}`,
        display_title: nodeType === 'action' ? 'Navigation' : 'Product Gallery',
        type: nodeType,
        schema: []
    };
    event.dataTransfer.setData('application/json', JSON.stringify(schemaData));
  };

  const onDragEnd = (event) => {
    event.target.classList.remove('dragging');
    document.querySelectorAll('.drop-zone-indicator').forEach(zone => {
      zone.classList.remove('drag-active');
    });
  };

  return (
    <div 
      className="toolbar"
      style={{
        position: 'absolute',
        left: `${position.x}px`,
        top: `${position.y}px`,
        transform: 'translateY(-50%)',
        zIndex: 5, // Match tooltip z-index
      }}
    >
      <div className="bg-white dark:bg-zinc-900 rounded-lg shadow-lg border border-gray-300 dark:border-white/20">
        {/* Drag handle */}
        <div 
          className="h-6 bg-gray-100 dark:bg-zinc-800 rounded-t-lg border-b border-gray-200 dark:border-white/20 cursor-move flex items-center justify-center"
          onMouseDown={handleMouseDown}
        >
          <div className="flex gap-1">
            {[...Array(3)].map((_, i) => (
              <div 
                key={i} 
                className="w-1 h-1 rounded-full bg-gray-400 dark:bg-white/75"
              />
            ))}
          </div>
        </div>

        <div className="p-2 space-y-2">
          {/* Toggle button for tooltip */}
          <button
            onClick={onToggleTooltip}
            className="w-full group border border-gray-300 dark:border-white/20 rounded-lg p-4 hover:bg-gray-50 dark:hover:bg-zinc-800 hover:shadow-xs active:bg-gray-200 dark:active:bg-zinc-700 transition-all duration-150 flex items-center space-x-4"
          >
            <div className="h-6 w-6 text-gray-500">
              {isTooltipVisible ? (
                React.createElement(IconMap['EyeSlashIcon'], { className: 'h-6 w-6' })
              ) : (
                React.createElement(IconMap['EyeIcon'], { className: 'h-6 w-6' })
              )}
            </div>
            <div>
              <div className="text-sm font-semibold text-gray-700 group-hover:text-gray-900 dark:text-white/75 dark:group-hover:text-white/75">
                {isTooltipVisible ? 'Hide Editor' : 'Show Editor'}
              </div>
            </div>
          </button>

          {nodeTypes.map(({ type, label, iconClass }) => (
            <div
              key={type}
              className="group border border-gray-300 dark:border-white/20 rounded-lg p-4 cursor-move hover:bg-gray-50 dark:hover:bg-zinc-800 hover:shadow-xs active:bg-gray-200 transition-all duration-150 flex items-center space-x-4"
              onDragStart={(e) => onDragStart(e, type)}
              onDragEnd={onDragEnd}
              draggable
            >
              {/* Icon */}
              <div className={`icon ${iconClass} dark:bg-zinc-800`} />

              {/* Label */}
              <div>
                <div className="text-sm font-semibold text-gray-700 group-hover:text-gray-900 dark:text-white/75  dark:group-hover:text-white/75">
                  {label}
                </div>
                <div className="text-xs text-gray-500 group-hover:text-gray-700 dark:group-hover:text-white/75">
                  Drag to add
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Inline styles */}
      <style>
        {`
          .icon {
            position: relative;
            display: inline-block;
            width: 2rem;
            height: 2rem;
            background-color: #f3f4f6; /* Tailwind bg-gray-100 */
            border: 1px solid #d1d5db; /* Tailwind border-gray-300 */
            border-radius: 0.25rem; /* Tailwind rounded */
          }

          /* Nav Item Icon */
          .icon-nav::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 45%; /* Adjusted from 50% to 48% for better centering */
            width: 0.5rem;
            height: 0.5rem;
            border-top: 2px solid #6b7280; /* Tailwind border-gray-500 */
            border-right: 2px solid #6b7280;
            transform: translate(-50%, -50%) rotate(45deg);
          }

          /* Product Tiles Icon */
          .icon-product {
            display: grid;
            grid-template-columns: repeat(3, 0.8rem);
            grid-template-rows: repeat(3, 0.8rem);
            gap: 0.2rem;
          }
          .icon-product div {
            background-color: #e5e7eb; /* Tailwind bg-gray-200 */
            border: 1px solid #d1d5db; /* Tailwind border-gray-300 */
            width: 100%;
            height: 100%;
          }
          .icon-product div:nth-child(7),
          .icon-product div:nth-child(8) {
            visibility: hidden;
          }
        `}
      </style>
    </div>
  );
};

export default CustomToolbar;
