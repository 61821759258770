import React, { useState, useEffect, useRef } from 'react';
import { RadioGroup } from '@headlessui/react';
import { ChromePicker } from 'react-color';
import { IconMap } from '../../config/IconMap';

// Helper function for CSS class names
function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

const ColorSquares = ({ primary, secondary, accent, onColorChange }) => (
    <div className="flex items-center space-x-2">
        {['primary', 'secondary', 'accent'].map((field) => (
            <div
                key={field}
                className="relative group h-8 w-8 rounded-md cursor-pointer"
                style={{ backgroundColor: field === 'primary' ? primary : field === 'secondary' ? secondary : accent }}
                onClick={() => onColorChange(field)}
            >
                <div className="absolute inset-0 flex items-center justify-center">
                    {React.createElement(IconMap['SwatchIcon'], {
                        className: 'h-5 w-5 text-white opacity-0 group-hover:opacity-100',
                        'aria-hidden': 'true',
                    })}
                </div>
            </div>
        ))}
    </div>
);

function StyleGuide({ onStyleGuideChange, currentStyleGuide }) {
    const [selectedStyleGuide, setSelectedStyleGuide] = useState(currentStyleGuide || {});
    const [showColorPicker, setShowColorPicker] = useState({ field: '', visible: false });
    const [tempColors, setTempColors] = useState({
        primary: currentStyleGuide?.primary || '#ffffff',
        secondary: currentStyleGuide?.secondary || '#ffffff',
        accent: currentStyleGuide?.accent || '#ffffff',
    });

    const colorPickerRef = useRef(null);

    useEffect(() => {
        if (currentStyleGuide) {
            setSelectedStyleGuide(currentStyleGuide);
            setTempColors({
                primary: currentStyleGuide.primary || '#ffffff',
                secondary: currentStyleGuide.secondary || '#ffffff',
                accent: currentStyleGuide.accent || '#ffffff',
            });
        }
    }, [currentStyleGuide]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (colorPickerRef.current && !colorPickerRef.current.contains(event.target)) {
                setShowColorPicker({ field: '', visible: false });
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleColorChange = (color, field) => {
        const updatedStyleGuide = { ...selectedStyleGuide, [field]: color.hex };
        setTempColors((prev) => ({ ...prev, [field]: color.hex }));
        setSelectedStyleGuide(updatedStyleGuide);
        onStyleGuideChange(updatedStyleGuide);
    };

    const toggleColorPicker = (field) => {
        setShowColorPicker((prev) => ({
            field: field,
            visible: !prev.visible || prev.field !== field,
        }));
    };

    return (
        <div className="px-6 py-6">
            {selectedStyleGuide && (
                <div className="mt-2 grid gap-y-4">
                    <div className="flex justify-between items-center w-full">
                        <div className="flex flex-col">
                            <span className="text-sm font-medium text-gray-900 dark:text-white/75">{selectedStyleGuide.title || 'Colour palette'}</span>
                            <span className="text-sm text-gray-500 dark:text-white/75">Customize your theme</span>
                        </div>
                        <ColorSquares
                            primary={tempColors.primary}
                            secondary={tempColors.secondary}
                            accent={tempColors.accent}
                            onColorChange={toggleColorPicker}
                        />
                    </div>
                    {showColorPicker.visible && showColorPicker.field && (
                        <div ref={colorPickerRef} className="absolute z-50 mt-4">
                            <ChromePicker
                                color={tempColors[showColorPicker.field]}
                                onChangeComplete={(color) => handleColorChange(color, showColorPicker.field)}
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default StyleGuide;
