import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';

/* Components */
import { editor_metadata } from '../config/config';
import UpgradeAccount from '../components/UpgradeAccount/UpgradeAccount';
import ProductGrid from '../components/ProductGrid/ProductGrid';
import Notification from '../components/Notifications/Notifications';
import useDarkMode from '../hooks/useDarkMode'; // Import the custom hook

export default function VendorProfile() {
    const [profile, setProfile] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showNotification, setShowNotification] = useState(false);
    const authToken = Cookies.get('auth_token');

    const [darkModeEnabled, handleToggleDarkMode] = useDarkMode(); // Use the custom hook

    const triggerNotification = () => {
        setShowNotification(true);
        setTimeout(() => {
            setShowNotification(false);
        }, 3000);
    };

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                if (!authToken) {
                    throw new Error('No authentication token found');
                }
                const response = await axios.get('/api/vendor-profile', {
                    headers: {
                        Authorization: `Token ${authToken}`,
                    },
                });
                setProfile(response.data);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching profile:", error);
                setLoading(false);
            }
        };
        fetchProfileData();
    }, [authToken]);

    if (loading) return <Skeleton className="my-2" count={1} height={40} width={250} />;

    return (
        <>
            <main className="lg:pl-72">
                <div className="flex flex-col lg:flex-row px-4 py-6 sm:px-6 lg:px-8 lg:py-6">
                    {profile.show_upgrade_form ? (
                        <div className="lg:w-2/3">
                            <UpgradeAccount context={editor_metadata.PRODUCT_EDIT} />
                        </div>
                    ) : (
                        <>
                            <div className="lg:w-2/3 lg:pr-6">
                                <ProductGrid triggerNotification={triggerNotification} />
                                <Notification 
                                    show={showNotification} 
                                    onClose={() => setShowNotification(false)}
                                />
                            </div>
                            
                        </>
                    )}
                </div>
            </main>
        </>
    );
}
