import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import useDarkMode from '../hooks/useDarkMode';
import Cookies from 'js-cookie';

/* Components */
import DetailRows from '../components/DetailRows/DetailRows';

export default function Settings({ context }) {

    const authToken = Cookies.get('auth_token');
    const [userData, setUserData] = useState(null);
    const [isDarkMode] = useDarkMode();

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await fetch('/api/profile', {
                    method: 'GET',
                    headers: { 'Authorization': `Token ${authToken}` },
                });

                if (response.ok) {
                    const data = await response.json();
                    setUserData(data);
                } else {
                    console.error('Request failed with status:', response.status);
                }
            } catch (error) {
                console.error('An error occurred:', error);
            }
        };
        fetchUserData();
    }, [authToken]);

    return (
        <>
            <main className="lg:pl-72">
                <div className="xl:pr-96">

                    <div className="px-4 py-6 sm:px-6 lg:px-8 lg:py-6">
                        {userData ? (
                            <>
                                <h1 className="text-base font-semibold text-gray-900 dark:text-white/75">
                                    Hey {userData.first_name} 👋
                                </h1>
                                <div className='grid grid-cols-1 gap-5 sm:grid-cols-1 xl:grid-cols-1'>
                                    <DetailRows context={context} userData={userData} />
                                </div>
                            </>
                        ) : (
                            <>
                                <h1 className="text-base font-semibold text-gray-900 dark:text-white/75">
                                    Account
                                </h1>
                                <Skeleton className="my-2 rounded-lg" count={1} height={144} width={378} />
                            </>
                        )}
                    </div>

                </div>
            </main>
        </>
    );
}
