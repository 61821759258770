import React, { useState, useEffect, useCallback } from 'react'
import { Link, useParams } from 'react-router-dom';

import Cookies from 'js-cookie';
import { IconMap } from '../config/IconMap';
import { QRCodeSVG } from 'qrcode.react';
import html2canvas from 'html2canvas';

/* Components */
import ConfirmationModal from '../components/ConfirmModal/ConfirmModal';
import ReactFlowEditor from '../components/ReactFlowEditor/ReactFlowEditor';
import CustomNode from '../components/ReactFlowEditor/CustomNode';
import AppDetailEditView from '../components/Apps/AppDetailEditView';
import StyleGuide from '../components/StyleGuides/StyleGuide';
import Notification from '../components/Notifications/Notifications';
import useDarkMode from '../hooks/useDarkMode';

export default function FlowEditor() {

    // States
    const [editedAppName, setEditedAppName] = useState('');
    const [editedAppDescription, setEditedAppDescription] = useState('');
    const [editedAppConfig, setEditedAppConfig] = useState([]);
    const [imageFile, setImageFile] = useState(null);
    const [backgroundImageUrl, setBackgroundImageUrl] = useState(null); // Add this line
    const [editedStyleGuide, setEditedStyleGuide] = useState({});
    const [changesMade, setChangesMade] = useState(false);

    const [isAppDisabled, setIsAppDisabled] = useState(false);
    const [isDisableModalOpen, setIsDisableModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [inactiveStatus, setInactiveStatus] = useState(false);

    const [darkModeEnabled, handleToggleDarkMode] = useDarkMode();

    // Add original state tracking
    const [originalState, setOriginalState] = useState({
        title: '',
        description: '',
        appConfig: [],
        styleGuide: {},
        imageFile: null
    });

    // Base URL for preview
    const { uid } = useParams();
    let baseURL = window.location.hostname === "localhost" ? "http://localhost:3000" : process.env.REACT_APP_URL;
    const previewURL = `${baseURL}/a/${uid}`;

    // State to manage visibility
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const [isPreviewAsideVisible, setIsPreviewAsideVisible] = useState(false);
    const togglePreviewAside = () => setIsPreviewAsideVisible(!isPreviewAsideVisible);

    const [isMetaAsideVisible, setIsMetaAsideVisible] = useState(true);
    const toggleMetaAside = () => setIsMetaAsideVisible(!isMetaAsideVisible);

    const [isControlsAsideVisible, setIsControlsAsideVisible] = useState(false);
    const toggleControlsAside = () => setIsControlsAsideVisible(!isControlsAsideVisible);

    const [showNotification, setShowNotification] = useState(false);
    let triggerNotification = () => {
        setIsPreviewAsideVisible(true);
        setShowNotification(true);
        setTimeout(() => {
            setShowNotification(false);
        }, 3000);
    };

    const downloadAsImage = () => {
        const element = document.querySelector('.qr-export');
        // Set better export options
        html2canvas(element, {
            backgroundColor: '#FFFFFF',
            scale: 2, // Increase quality
            logging: false,
            useCORS: true
        }).then((canvas) => {
            const link = document.createElement('a');
            link.href = canvas.toDataURL('image/png');
            link.download = `qrcode-${editedAppName.toLowerCase().replace(/\s+/g, '-')}.png`;
            link.click();
        });
    };

    useEffect(() => {
        const authToken = Cookies.get('auth_token');
        async function fetchAppInfo() {
            try {
                if (!uid || !authToken) {
                    console.error('There was an issue with this request.');
                    return;
                }
                const response = await fetch(`/api/get-app/${uid}`, {
                    method: 'GET',
                    headers: { 'Authorization': `Token ${authToken}` },
                });
                if (response.ok) {
                    const data = await response.json();

                    setEditedAppName(data.title);
                    setEditedAppDescription(data.description);
                    setEditedAppConfig(data.app_config || []);
                    setEditedStyleGuide(data.style_guide);
                    setBackgroundImageUrl(data.background_image_url); // Add this line
                    setIsAppDisabled(data.is_disabled || false);
                    setInactiveStatus(data.inactive_status || false);

                    // Check and set the background image if it exists
                    if (data.background_image_url) {
                        setImageFile(data.background_image_url);
                    }

                    // Store original state
                    setOriginalState({
                        title: data.title,
                        description: data.description,
                        appConfig: data.app_config || [],
                        styleGuide: data.style_guide,
                        imageFile: data.background_image_url
                    });

                    // Mobile use case
                    setIsSidebarOpen(false);

                } else {
                    console.error('Request failed with status:', response.status);
                    const errorData = await response.json();
                    console.error('Error response:', errorData);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        }
        fetchAppInfo();
    }, [uid]);

    // Add API handlers
    const handleDisableApp = async () => {
        try {
            const authToken = Cookies.get('auth_token');
            const response = await fetch(`/api/disable-app/${uid}/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Token ${authToken}`,
                }
            });

            if (response.ok) {
                const data = await response.json();
                setInactiveStatus(data.inactive);
                setIsAppDisabled(data.inactive); // Ensure this state is updated
                triggerNotification();
            } else {
                // Add error handling
                const errorData = await response.json();
                console.error('Disable app failed:', errorData);
            }
        } catch (error) {
            console.error('Error disabling app:', error);
        }
    };

    const handleDeleteApp = async () => {
        try {
            const authToken = Cookies.get('auth_token');
            const response = await fetch(`/api/delete-app/${uid}/`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Token ${authToken}`,
                }
            });
            if (response.ok) {
                // Redirect to apps list
                window.location.href = '/m';
            }
        } catch (error) {
            console.error('Error deleting app:', error);
        }
    };

    // Handle changes
    const handleAppNameChange = (newAppName) => {
        setEditedAppName(newAppName);
        setChangesMade(true);
    };

    const handleAppDescriptionChange = (newAppDescription) => {
        setEditedAppDescription(newAppDescription);
        setChangesMade(true);
    };

    const handleStyleGuideChange = (newStyleGuide) => {
        setEditedStyleGuide(newStyleGuide);
        setChangesMade(true);
    };

    // The image has changed prior to publish
    const handleImageFileChange = (newImageFile) => {
        setImageFile(newImageFile);
        setChangesMade(true);
    };

    const handleAppConfigUpdate = useCallback((newConfig) => {
        if (Array.isArray(newConfig)) {
            setEditedAppConfig(newConfig);  // This updates the main state
            setChangesMade(true);
        }
    }, []);

    const addNewConfigItem = () => {
        const newItem = {
            id: `new-${Date.now()}`,
            display_title: 'New section',
            type: 'action',
            schema: []
        };
        setEditedAppConfig(prevConfig => [...prevConfig, newItem]);
        setChangesMade(true);
    };

    const deleteConfigItem = (itemId) => {
        const filteredItems = editedAppConfig.filter(item => item.id !== itemId);
        setEditedAppConfig(filteredItems);
    };

    let app_metadata = {
        title: editedAppName,
        description: editedAppDescription,
        app_config: editedAppConfig,
        style_guide: editedStyleGuide,
        background_image: imageFile,
        background_image_url: backgroundImageUrl,
    }

    // Function to handle the publish action
    const handlePublish = () => {
        const apiUrl = `/api/update-app/${uid}/`;
        const authToken = Cookies.get('auth_token');

        // Create an instance of FormData to hold the form fields and file data
        const formData = new FormData();

        // Append the text data
        formData.append('display_title', editedAppName);
        formData.append('display_description', editedAppDescription);
        formData.append('app_config', JSON.stringify(editedAppConfig));

        // Append styleguide if it's present
        if (editedStyleGuide && Object.keys(editedStyleGuide).length > 0) {
            formData.append('style_guide', JSON.stringify(editedStyleGuide));
        }

        // Append the image file if it's present
        if (imageFile) {
            formData.append('background_image', imageFile);
        }

        fetch(apiUrl, {
            method: 'PUT',
            headers: {
                'Authorization': `Token ${authToken}`,
            },
            body: formData,
        }).then((response) => {
            if (response.ok) {
                setChangesMade(false);
                triggerNotification();

                if (window.innerWidth < 1280) {
                    setIsSidebarOpen(false);
                }

            } else {
                console.error('Failed to update app');
            }
        })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    const handleDiscard = () => {
        // Revert to original state
        setEditedAppName(originalState.title);
        setEditedAppDescription(originalState.description);
        setEditedAppConfig(originalState.appConfig);
        setEditedStyleGuide(originalState.styleGuide);
        setImageFile(originalState.imageFile);
        setChangesMade(false);
    };

    const handleToggleAppStatus = () => {
        if (inactiveStatus) {
            handleDisableApp(); // Directly enable the app
        } else {
            setIsDisableModalOpen(true); // Show confirmation modal for disabling
        }
    };

    return (
        <>
            <main className="lg:pl-72">
                <div className="xl:pr-96">
                    <ReactFlowEditor
                        app_metadata={app_metadata}
                        appConfig={editedAppConfig} 
                        background_image={imageFile}
                        onAppConfigUpdate={handleAppConfigUpdate}
                        onAddItem={addNewConfigItem}
                        onDeleteItem={deleteConfigItem}
                        CustomNode={CustomNode}
                        inactiveStatus={inactiveStatus}
                    />
                </div>
                <aside className={`fixed inset-y-0 right-0 w-96 overflow-y-auto border-l bg-white dark:bg-zinc-900 border-gray-200 dark:border-white/10 transform transition-transform duration-300 ease-in-out ${isSidebarOpen ? "translate-x-0" : "translate-x-full"} xl:translate-x-0 xl:right-0 z-40`}>
                    <div className="mt-auto h-1/3 border-t border-gray-300 dark:border-white/10">
                        <button onClick={togglePreviewAside} className={`flex w-full gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 dark:text-white/75 hover:bg-gray-50 dark:hover:bg-zinc-800 ${isPreviewAsideVisible ? '' : ''}`}>
                            {
                                isPreviewAsideVisible ? (
                                    <>
                                        {React.createElement(
                                            IconMap['MinusIcon'],
                                            {
                                                className: 'h-5 w-5 text-gray-500',
                                                'aria-hidden': 'true',
                                            }
                                        )}
                                        Preview
                                    </>
                                ) : (
                                    <>
                                        {React.createElement(
                                            IconMap['PlusIcon'],
                                            {
                                                className: 'h-5 w-5 text-gray-500',
                                                'aria-hidden': 'true',
                                            }
                                        )}
                                        Preview
                                    </>
                                )
                            }
                            <span className="sr-only">Preview</span><span aria-hidden="true"></span>
                        </button>
                        <div className={`flex w-full gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 dark:text-white/75 ${isPreviewAsideVisible ? 'block' : 'hidden'}`}>
                            <div className="flex gap-6">
                                <div className="qr-export rounded-lg shadow-sm" style={{ width: '140px', height: '140px' }}>
                                    <QRCodeSVG
                                        value={previewURL}
                                        size={116}
                                        level="H"
                                        includeMargin={true}
                                        className="w-full h-full"
                                    />
                                </div>
                                <div className="flex-1 min-w-[180px]">
                                    <label htmlFor="app-url" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white/75">
                                        App URL
                                    </label>
                                    <p className="mt-2 text-sm leading-6 text-gray-600 dark:text-white/75">This is the unique URL assigned to your app.</p>
                                    <div className="mt-3 flex flex-col gap-2">
                                        <Link
                                            target="_blank"
                                            to={previewURL}
                                            className="inline-flex items-center text-sm font-medium text-indigo-600 dark:text-indigo-300 dark:hover:text-indigo-400"
                                        >
                                            {React.createElement(
                                                IconMap['ArrowTopRightOnSquareIcon'],
                                                { className: 'h-4 w-4 mr-2 text-indigo-500 group-hover:text-indigo-900 dark:text-indigo-300 dark:group-hover:text-indigo-400' }
                                            )}
                                            <span>Visit URL</span>
                                        </Link>
                                        <Link
                                            onClick={downloadAsImage}
                                            className="inline-flex items-center text-sm font-medium text-indigo-600 dark:text-indigo-300 dark:hover:text-indigo-400"
                                        >
                                            {React.createElement(
                                                IconMap['ArrowDownTrayIcon'],
                                                { className: 'h-4 w-4 mr-2 text-indigo-500 group-hover:text-indigo-900 dark:text-indigo-300 dark:group-hover:text-indigo-400' }
                                            )}
                                            <span>Export QR code</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button onClick={toggleMetaAside} className="flex w-full gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 dark:text-white/75 hover:bg-gray-50 dark:hover:bg-zinc-800">
                            {
                                isMetaAsideVisible ? (
                                    <>
                                        {React.createElement(
                                            IconMap['MinusIcon'],
                                            {
                                                className: 'h-5 w-5 text-gray-500',
                                                'aria-hidden': 'true',
                                            }
                                        )}
                                        Configuration
                                    </>
                                ) : (
                                    <>
                                        {React.createElement(
                                            IconMap['PlusIcon'],
                                            {
                                                className: 'h-5 w-5 text-gray-500',
                                                'aria-hidden': 'true',
                                            }
                                        )}
                                        Configuration
                                    </>
                                )
                            }
                            <span className="sr-only">Set configuration for this app</span><span aria-hidden="true"></span>
                        </button>
                        <div className={`col-span-full ${isMetaAsideVisible ? 'block' : 'hidden'}`}>
                            <AppDetailEditView
                                display_title={editedAppName}
                                display_description={editedAppDescription}
                                background_image_url={app_metadata.background_image_url}
                                onAppNameChange={handleAppNameChange}
                                onAppDescriptionChange={handleAppDescriptionChange}
                                onImageFileChange={handleImageFileChange}
                                uid={uid}
                            />
                            <StyleGuide
                                currentStyleGuide={editedStyleGuide}
                                onStyleGuideChange={handleStyleGuideChange}
                            />
                        </div>
                        <button onClick={toggleControlsAside} className={`flex w-full gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 dark:text-white/75 hover:bg-gray-50 dark:hover:bg-zinc-800 ${isControlsAsideVisible ? '' : ''}`}>
                            {
                                isControlsAsideVisible ? (
                                    <>
                                        {React.createElement(
                                            IconMap['MinusIcon'],
                                            {
                                                className: 'h-5 w-5 text-gray-500',
                                                'aria-hidden': 'true',
                                            }
                                        )}
                                        Settings
                                    </>
                                ) : (
                                    <>
                                        {React.createElement(
                                            IconMap['PlusIcon'],
                                            {
                                                className: 'h-5 w-5 text-gray-500',
                                                'aria-hidden': 'true',
                                            }
                                        )}
                                        Settings
                                    </>
                                )
                            }
                            <span className="sr-only">Settings for this app</span><span aria-hidden="true"></span>
                        </button>
                        <div className={`bg-white dark:bg-zinc-900 px-2 py-2 ${isControlsAsideVisible ? 'block' : 'hidden'}`}>
                            <div className='flex items-start justify-between flex-wrap'>
                                <div className="w-1/2 p-1">
                                    <dt className="p-2 m-1 text-sm font-medium text-gray-900 dark:text-white/75">{inactiveStatus ? 'Enable app' : 'Disable app'}</dt>
                                </div>
                                <div className="w-1/2 flex justify-end">
                                    <button onClick={handleToggleAppStatus} className={`w-full m-2 ml-2 rounded-md ${inactiveStatus ? 'bg-indigo-600' : 'bg-red-600'} px-3 py-2 text-sm font-semibold text-white shadow-sm ${inactiveStatus ? 'hover:bg-indigo-500 dark:hover:bg-indigo-700' : 'hover:bg-red-500 dark:hover:bg-red-700'}`}>
                                        {inactiveStatus ? 'Enable' : 'Disable'}
                                    </button>
                                </div>
                            </div>
                            <div className='flex items-start justify-between flex-wrap '>
                                <div className="w-1/2 p-1">
                                    <dt className="p-2 m-1 text-sm font-medium text-gray-900 dark:text-white/75">Delete app</dt>
                                </div>
                                <div className="w-1/2 flex justify-end">
                                    <button
                                        onClick={() => setIsDeleteModalOpen(true)}
                                        className="w-full m-2 ml-2 rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm disabled:bg-gray-100 disabled:text-gray-700 dark:disabled:bg-zinc-800 dark:disabled:text-zinc-400 hover:bg-red-500 dark:hover:bg-red-700"
                                        disabled={!inactiveStatus}
                                    >
                                        Delete
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="w-full">
                            <button
                                onClick={handlePublish}
                                disabled={!changesMade}
                                className={`w-full flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 ${changesMade
                                    ? 'bg-green-500 dark:bg-green-600 text-white hover:bg-green-600 dark:hover:bg-green-700 cursor-pointer'
                                    : 'bg-gray-300 text-gray-600 cursor-not-allowed'
                                    }`}
                            >
                                <span className="sr-only">Current draft option: </span>
                                <span aria-hidden="true">Publish</span>
                            </button>
                            <button
                                onClick={handleDiscard}
                                disabled={!changesMade}
                                className={`w-full flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 ${changesMade
                                    ? 'text-gray-900 dark:text-white/75 hover:bg-gray-50 dark:hover:bg-zinc-800 cursor-pointer'
                                    : 'text-gray-400 dark:text-white/75 cursor-not-allowed'
                                    }`}
                            >
                                <span className="sr-only">Current draft option: </span>
                                <span aria-hidden="true">Discard</span>
                            </button>
                        </div>
                    </div>
                </aside>
            </main>

            <Notification
                show={showNotification}
                onClose={() => setShowNotification(false)}
            />

            <ConfirmationModal
                title="Disable App"
                contextMsg="Are you sure you want to disable this app? Users will no longer be able to access it."
                open={isDisableModalOpen}
                label="Disable"
                onCancel={() => setIsDisableModalOpen(false)}
                onConfirm={handleDisableApp}
            />

            <ConfirmationModal
                title="Delete App"
                contextMsg="Are you sure you want to delete this app? This action cannot be undone."
                open={isDeleteModalOpen}
                label="Delete"
                onCancel={() => setIsDeleteModalOpen(false)}
                onConfirm={handleDeleteApp}
            />

        </>
    );
}
