import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';

import Cookies from 'js-cookie';
import axios from 'axios';

import ConfirmationModal from '../ConfirmModal/ConfirmModal';
import useDarkMode from '../../hooks/useDarkMode';
import { IconMap } from '../../config/IconMap';

export default function DetailRows({ userData, context }) {

  const [isModalOpen, setModalOpen] = useState(false);
  const [currentField, setCurrentField] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [generalError, setGeneralError] = useState('');
  const [loading, setLoading] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const authToken = Cookies.get('auth_token');
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPEKEY);

  const [darkModeEnabled, handleToggleDarkMode] = useDarkMode(); // Use the custom hook

  const [vendorProfile, setVendorProfile] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [loadingTransactions, setLoadingTransactions] = useState(true);

  const handleOpenDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  // Modified button code with iPad support
  const handleStripeDashboardRedirect = async () => {
    try {
      const response = await axios.get('/api/financials', {
        headers: {
          Authorization: `Token ${authToken}`,
        },
      });
      const stripeDashboardUrl = response.data.url;

      // iOS/iPadOS detection
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

      if (isIOS) {
        // Direct navigation for iOS
        window.location.href = stripeDashboardUrl;
      } else {
        // Popup for desktop
        const popup = window.open(stripeDashboardUrl, '_blank');
        if (!popup || popup.closed || typeof popup.closed === 'undefined') {
          // Fallback if popup is blocked
          window.location.href = stripeDashboardUrl;
        }
      }
    } catch (error) {
      console.error("Error fetching Stripe dashboard link:", error);
      alert("Could not redirect to Stripe dashboard. Please try again later.");
    }
  };

  const handleDeleteAccount = async () => {
    setIsSaving(true);
    try {
      const response = await fetch('/api/delete-account/', {
        method: 'DELETE',
        headers: {
          'Authorization': `Token ${authToken}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) throw new Error('Failed to delete account.');

      // Redirect or show success message after deletion
      setDeleteModalOpen(false);
    } catch (error) {
      setGeneralError('Failed to delete account. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };

  const statusStyle = userData.subscription_status === 'active'
    ? 'inline-flex items-center rounded-md bg-green-50 dark:bg-zinc-800 px-2 py-1 text-xs font-medium text-green-700 dark:text-green-300 ring-1 ring-inset ring-green-600/20'
    : 'inline-flex items-center rounded-md bg-orange-50 dark:bg-zinc-800 px-2 py-1 text-xs font-medium text-orange-700 dark:text-orange-300 ring-1 ring-inset ring-orange-600/20';

  const capitalize = (text) => {
    if (text) {
      return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    }
    return '';
  };

  const formatDate = (dateString) => {
    if (!dateString) {
      return "No end date";
    }
    const [year, month, day] = dateString.split('-');
    return `${day}-${month}-${year}`;
  };

  const getFieldValue = (field) => {
    if (field.type === 'concat') {
      return field.targets.map(target => userData[target] || '').join(' ');
    }
    return userData[field.target] || field.placeholder;
  };

  const handleEditClick = (field) => {
    setCurrentField(field);
    if (field.type === 'concat') {
      setFirstName(userData[field.targets[0]] || '');
      setLastName(userData[field.targets[1]] || '');
    }
    setModalOpen(true);
  };

  const validateInput = (input) => {
    const regex = /^[A-Za-z]*$/;
    return regex.test(input) && input.trim() !== '';
  };

  const handleSave = async () => {
    setFirstNameError('');
    setLastNameError('');
    setGeneralError('');

    let payload = {};

    if (currentField?.type === 'concat') {
      if (!validateInput(firstName)) {
        setFirstNameError('Please enter a valid first name using only letters.');
        return;
      }

      if (!validateInput(lastName)) {
        setLastNameError('Please enter a valid last name using only letters.');
        return;
      }
      payload.first_name = firstName;
      payload.last_name = lastName;
    }

    setIsSaving(true);

    try {
      const response = await fetch('/api/update-profile/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${authToken}`,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Failed to save. Please try again.');
      }

      const updatedData = await response.json();

      if (currentField?.type === 'concat') {
        userData[currentField.targets[0]] = updatedData.first_name;
        userData[currentField.targets[1]] = updatedData.last_name;
      }

      setModalOpen(false);
    } catch (error) {
      setGeneralError(error.message || 'Failed to save. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    const fetchVendorProfile = async () => {
      try {
        if (!authToken) return;
        const response = await axios.get('/api/vendor-profile', {
          headers: { Authorization: `Token ${authToken}` },
        });
        setVendorProfile(response.data);
      } catch (error) {
        console.error("Error fetching vendor profile:", error);
      }
    };
    fetchVendorProfile();
  }, [authToken]);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        if (vendorProfile && vendorProfile.vendor_level === 2) {
          setLoadingTransactions(true);
          const response = await axios.get('/api/recent-transactions', {
            headers: { Authorization: `Token ${authToken}` },
          });
          setTransactions(response.data.charges);
        }
      } catch (error) {
        console.error("Error fetching recent transactions:", error);
      } finally {
        setLoadingTransactions(false);
      }
    };

    if (vendorProfile && vendorProfile.vendor_level === 2) {
      fetchTransactions();
    }
  }, [vendorProfile, authToken]);

  const handleManagePayments = async () => {
    setLoading(true);
    setGeneralError('');

    try {
      // Await the stripe object
      const stripe = await stripePromise;

      if (userData.subscription_type === 'Basic') {
        const response = await fetch('/api/upgrade-premium/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${authToken}`,
          },
          body: JSON.stringify({ price_id: process.env.REACT_APP_PREMIUM_MONTHLY_PRICE_ID }), // Use the price ID from env
        });

        const data = await response.json();
        if (response.ok) {
          // Redirect to Stripe Checkout using the Stripe object
          const { error } = await stripe.redirectToCheckout({ sessionId: data.id });
          if (error) {
            setGeneralError(error.message || 'Failed to redirect to checkout.');
          }
        } else {
          setGeneralError(data.error || 'Failed to create checkout session.');
        }
      } else {
        const response = await fetch('/api/create-session/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${authToken}`
          },
        });

        const data = await response.json();
        if (response.ok) {
          window.location.href = data.url;
        } else {
          setGeneralError(data.error || 'Failed to create session.');
        }
      }
    } catch (err) {
      setGeneralError('An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };


  // Status Color Coding
  const getStatusClass = (status) => {
    switch (status) {
      case 'succeeded':
        return 'text-green-600 dark:text-green-300';
      case 'pending':
        return 'text-yellow-600 dark:text-yellow-300';
      case 'failed':
        return 'text-red-600 dark:text-red-300';
      default:
        return 'text-gray-500 dark:text-gray-300';
    }
  };

  return (
    <>
      <div className="sm:w-full flex flex-col md:flex-row md:gap-16 mt-6">
        <dl className="sm:w-full lg:w-2/4 space-y-6 divide-y divide-gray-100 dark:divide-white/10 border-t border-gray-200 dark:border-white/10 text-sm leading-6">
          {context.SCHEMA.values.map((field, index) => (
            <div key={index} className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 dark:text-white/75 sm:w-64 sm:flex-none sm:pr-6">{field.field}</dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto text-gray-900 dark:text-white/75">
                <span>{getFieldValue(field)}</span>
                {field.editable && (
                  <button
                    type="button"
                    className="font-semibold text-indigo-600 hover:text-indigo-500 dark:text-indigo-400 dark:hover:text-indigo-300"
                    onClick={() => handleEditClick(field)}
                  >
                    Edit
                  </button>
                )}
              </dd>
            </div>
          ))}

          {/* Dark Mode Toggle */}
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 dark:text-white/75 sm:w-64 sm:flex-none sm:pr-6">Dark mode</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <span></span>
              <button
                type="button"
                className="font-semibold text-indigo-600 hover:text-indigo-500 dark:text-indigo-400 dark:hover:text-indigo-300"
                onClick={handleToggleDarkMode}
              >
                {darkModeEnabled ? 'Disable' : 'Enable'}
              </button>
            </dd>
          </div>

          {/* Close Account Row */}
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 dark:text-white/75 sm:w-64 sm:flex-none sm:pr-6">Close account</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <span></span>
              <button
                type="button"
                className="font-semibold text-red-600 dark:text-red-300 hover:text-red-500 dark:hover:text-red-500"
                onClick={handleOpenDeleteModal}
              >
                Close account
              </button>
            </dd>
          </div>
        </dl>

        {process.env.REACT_APP_SAAS_ENABLED === 'true' && (
          <div className="mt-6 sm:w-full lg:w-1/4 rounded-lg bg-white dark:bg-zinc-800 shadow-sm ring-1 ring-gray-900/5 md:mt-0">
            <dl className="flex flex-wrap">
              <div className="flex-auto pl-6 pt-6">
                <dd className="mt-1 text-base font-semibold leading-6 text-gray-900 dark:text-white/75">{capitalize(userData.subscription_type)} plan</dd>
              </div>
              <div className="flex-none self-end px-6 pt-4">
                <dt className="sr-only">Status</dt>
                <dd className={statusStyle}>
                  {capitalize(userData.subscription_status)}
                </dd>
              </div>
              <div className="mt-6 flex w-full flex-none gap-x-4 border-t border-gray-900/5 dark:border-white/10 pt-6 px-2">
                <dt className="flex-none">
                  <span className="sr-only">Your plan</span>
                </dt>
                <dd className="text-sm font-medium leading-6 text-gray-900 dark:text-white/75">
                  Billing period end: <br />
                  <strong>{formatDate(userData.subscription_end_date)}</strong>
                </dd>
              </div>
            </dl>
            <div className="mt-6 border-t border-gray-900/5 dark:border-white/10 px-6 py-6">
              <a
                href="#"
                onClick={handleManagePayments}
                className="text-sm font-semibold leading-6 text-gray-900 dark:text-white/75"
              >
                {userData.subscription_type === 'Basic' ? 'Upgrade to unlock more features' : 'Manage payments'} <span aria-hidden="true">&rarr;</span>
              </a>
              {loading && <p className="mt-2 text-sm text-gray-500 dark:text-white/75">Redirecting to Stripe...</p>}
              {generalError && <p className="mt-2 text-sm text-red-600 dark:text-red-300">{generalError}</p>}
            </div>
          </div>
        )}
      </div>

      {/* Confirmation Modal for Account Deletion */}
      <ConfirmationModal
        title="Are you sure you want to close your account?"
        contextMsg="Users will no longer be able to access your apps and products. We will also close any attached merchant accounts."
        open={isDeleteModalOpen}
        onCancel={() => setDeleteModalOpen(false)}
        onConfirm={handleDeleteAccount}
      />

      {isModalOpen && (
        <div className="fixed inset-0 z-10 flex items-center justify-center bg-gray-400 dark:bg-zinc-800 duration-1000 ease-in-out bg-opacity-75 dark:bg-opacity-50 transition-opacity">
          <div className="relative transform overflow-hidden rounded-lg text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
            <div className="bg-white dark:bg-zinc-900 p-6 shadow-lg">
              <h3 className="text-base font-semibold leading-6 text-gray-900 dark:text-white/75">Edit {currentField?.field}</h3>
              {currentField?.type === 'concat' && (
                <>
                  <div className="mt-4">
                    <label className="block text-sm font-medium text-gray-700 dark:text-white/75">First Name</label>
                    <input
                      type="text"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      className="mt-1 w-full p-2 border border-gray-300 dark:border-white/10 dark:bg-zinc-800 rounded-md dark:text-white/75 dark:placeholder:text-white/75"
                    />
                    {firstNameError && <p className="mt-2 text-sm text-red-600">{firstNameError}</p>}
                  </div>
                  <div className="mt-4">
                    <label className="block text-sm font-medium text-gray-700 dark:text-white/75">Last Name</label>
                    <input
                      type="text"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      className="mt-1 w-full p-2 border border-gray-300 dark:border-white/10 dark:bg-zinc-800 rounded-md dark:text-white/75 dark:placeholder:text-white/75"
                    />
                    {lastNameError && <p className="mt-2 text-sm text-red-600">{lastNameError}</p>}
                  </div>
                </>
              )}
              {generalError && <p className="mt-2 text-sm text-red-600 dark:text-red-50 ">{generalError}</p>}
            </div>
            <div className="bg-gray-50 dark:bg-zinc-800 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
              <button
                className={`inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-700 sm:ml-3 sm:w-auto ${isSaving ? 'cursor-not-allowed opacity-50' : ''}`}
                onClick={handleSave}
                disabled={isSaving}
              >
                {isSaving ? 'Saving...' : 'Save'}
              </button>
              <button
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white dark:bg-zinc-800 dark:hover:bg-zinc-900 px-3 py-2 text-sm font-semibold text-gray-900 dark:text-slate-100 shadow-sm ring-1 ring-inset ring-gray-500 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                onClick={() => setModalOpen(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {process.env.REACT_APP_SAAS_ENABLED === 'true' && (
        <div className="pt-8">
          <div className="mt-6">
            <div className="sm:w-full md:w-2/3 mt-4">
              <div>
                {loadingTransactions ? (
                  <p className="p-4 dark:text-white/75">Loading transactions...</p>
                ) : transactions.length > 0 ? (
                  <>
                    <h2 className="sr-only">Recent transactions</h2>
                    <h1 className="text-base font-semibold text-gray-900 dark:text-white/75">Recent transactions</h1>
                    <p className="mt-1 text-sm text-gray-500 dark:text-slate-100">
                      You can manage your merchant account via the 'Manage payments' button below.
                    </p>
                    <ul role="list" className="mt-6 shadow-sm divide-y divide-gray-100 dark:divide-white/10 bg-white dark:bg-zinc-800 rounded-lg border border-gray-200 dark:border-white/10">
                      {transactions.map((transaction, index) => (
                        <li key={index} className="relative flex justify-between gap-x-6 px-4 py-5 sm:px-6">
                          <div className="flex min-w-0 gap-x-4">
                            <div className="min-w-0 flex-auto">
                              <p className="text-sm font-semibold leading-6 text-gray-900 dark:text-white/75">
                                Amount: ${(transaction.amount / 100).toFixed(2)}
                              </p>
                              <p className={`mt-1 flex text-xs leading-5 capitalize ${getStatusClass(transaction.status)}`}>
                                {transaction.status}
                              </p>
                            </div>
                          </div>
                          <div className="flex shrink-0 items-center gap-x-4">
                            <p className="text-sm leading-6 text-gray-900 dark:text-white/75">Transaction</p>
                            <p className="mt-1 text-xs leading-5 text-gray-500 dark:text-white/75">
                              <time dateTime={new Date(transaction.created * 1000).toISOString()}>
                                {new Date(transaction.created * 1000).toLocaleString()}
                              </time>
                            </p>
                          </div>
                        </li>
                      ))}
                    </ul>
                    <div className="mt-6">
                      <button
                        className="inline-flex items-center justify-center rounded-md bg-indigo-600 hover:bg-indigo-500 dark:hover:bg-indigo-700 px-3 py-2 text-sm font-semibold text-white shadow-sm 
                                                    active:bg-indigo-700 
                                                    touch-manipulation 
                                                    -webkit-tap-highlight-color-transparent"
                        onClick={handleStripeDashboardRedirect}
                        role="button"
                        aria-label="Open financial dashboard"
                      >
                        Access financial dashboard
                        {/* Adding the external link icon */}
                        {React.createElement(
                          IconMap['ArrowTopRightOnSquareIcon'],
                          {
                            className: 'ml-2 h-5 w-5 text-white',
                            'aria-hidden': 'true'
                          }
                        )}
                      </button>
                    </div>
                  </>
                ) : (
                  <p className='p-4 dark:text-white/75'>No recent transactions found.</p>
                )}
              </div>
            </div>

          </div>
        </div>
      )}
    </>
  );
}