import React, { useState, useEffect } from 'react';
import { Transition, Dialog, Disclosure } from '@headlessui/react';
import { IconMap } from '../../config/IconMap';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

/* Components */
import CartModal from '../public_components/cartModal';
import SlideOverPanel from '../public_components/SlideOverPanel';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPEKEY);

export default function SaasMenu({ previewMode = false, showBackButton = false, cartItems, updateCart, removeItem, appUid, styleGuide, clearCart, handlePaymentModal, country, currency, recentActivity }) {

  // Controls
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isCartOpen, setIsCartOpen] = useState(false);

  // Calculate the total quantity of items in the cart
  const totalItemsInCart = cartItems.reduce((acc, item) => acc + item.quantity, 0);

  // Functions
  const handleSidebarToggle = () => {
    setSidebarOpen(!sidebarOpen);
  };
  const handleButtonClick = () => {
    window.history.back();
  };
  const handleCartClick = () => {
    setIsCartOpen(true); // Open the cart modal when cart icon is clicked
  };

  // Show more / handle less or more clicks - to be removed
  const [showMore, setShowMore] = useState(false);
  const handleShowMoreClick = () => setShowMore(true);
  const handleShowLessClick = () => setShowMore(false);

  return (
    <header className="pb-10 pt-4">
      <div className="mx-auto max-w-3xl lg:max-w-5xl px-4 sm:px-4 lg:px-4 flex items-center">
        {/* Back Button */}
        {!previewMode && showBackButton && (
          <div className="flex justify-start">
            <Disclosure>
              {({ open }) => (
                <>
                  <button
                    onClick={handleButtonClick}
                    className="relative inline-flex items-center justify-center rounded-md text-white py-2 pr-2 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2"
                  >
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Open main menu</span>
                    {React.createElement(IconMap['ChevronLeftIcon'], {
                      className: 'block h-6 w-6',
                      'aria-hidden': 'true',
                    })}
                    <span className="px-2">Back</span>
                  </button>
                </>
              )}
            </Disclosure>
          </div>
        )}

        <div className="ml-auto flex items-center space-x-4">
          {/* Cart Icon */}
          {totalItemsInCart > 0 && (
            <button
              onClick={handleCartClick}
              className="ml-2 relative inline-flex items-center justify-center rounded-md text-white p-2 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2">
              {React.createElement(IconMap['ShoppingCartIcon'], {
                className: 'block h-6 w-6 text-white',
                'aria-hidden': 'true',
              })}
              <span className="absolute top-0 right-0 bg-red-600 text-white rounded-full text-xs px-1">{totalItemsInCart}</span>
            </button>
          )}

          {/* Sidebar toggle 
          <button
            onClick={handleSidebarToggle}
            className="ml-2 relative inline-flex items-center justify-center rounded-md text-white p-2 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2"
            style={{ zIndex: 51 }}>
            <span className="absolute -inset-0.5" />
            <span className="sr-only">Toggle menu</span>
            {React.createElement(
              sidebarOpen ? IconMap['XMarkIcon'] : IconMap['Bars3BottomRightIcon'],
              { className: 'block h-6 w-6', 'aria-hidden': 'true' }
            )}
          </button>
          */}
          <SlideOverPanel isOpen={sidebarOpen} onClose={() => setSidebarOpen(false)}>
            <div className="relative mx-auto flex w-full py-12">
              {/* Recent Activity Section */}
              {recentActivity && (
                <div className="mt-4 mx-auto w-full md:w-96">
                  <p className="tracking-tight text-base text-white font-semibold mx-4">
                    Recent Purchases
                  </p>
                  <div className="mt-4 mx-4 overflow-hidden rounded-lg bg-white shadow">
                    <ul>
                      {recentActivity.config
                        .slice(0, showMore ? recentActivity.config.length : 1)
                        .map((activity, index) => (
                          <li key={index} className="group block">
                            <div className="flex items-center px-3 py-3">
                              <div className="min-w-0 flex-1">
                                <p className="truncate text-sm font-medium text-gray-900">
                                  {activity.type === 'submission' ? 'Form submission' : 'Other'}
                                </p>
                                <p className="mt-2 text-sm text-gray-500">{activity.date}</p>
                              </div>
                              {React.createElement(IconMap['ChevronRightIcon'], {
                                className: 'h-5 w-5 text-gray-400',
                                'aria-hidden': 'true',
                              })}
                            </div>
                          </li>
                        ))}
                    </ul>
                  </div>
                  {recentActivity.config.length > 1 && (
                    <div className="mt-2 mx-4 flex justify-center">
                      {!showMore ? (
                        <button
                          onClick={handleShowMoreClick}
                          className="text-sm text-gray-200 py-2 hover:text-gray-100 transition"
                        >
                          View more
                        </button>
                      ) : (
                        <button
                          onClick={handleShowLessClick}
                          className="text-sm text-white py-2 hover:text-gray-100 transition"
                        >
                          Show less
                        </button>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </SlideOverPanel>
        </div>

        {/* Cart Modal */}
        <Elements stripe={stripePromise}>
          <CartModal
            isOpen={isCartOpen}
            closeModal={() => setIsCartOpen(false)}
            cartItems={cartItems} // Use the actual cartItems from props
            updateCart={updateCart} // Pass the updateCart function
            removeItem={removeItem} // Pass the removeItem function
            handlePaymentModal={handlePaymentModal} // confirms via modal
            appUid={appUid}
            styleGuide={styleGuide}
            clearCart={clearCart}
            country={country}
            currency={currency}
          />
        </Elements>
      </div>
    </header>
  );
}
