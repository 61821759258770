import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import Cookies from 'js-cookie';
import useDarkMode from '../../hooks/useDarkMode';

// components
import CreateAside from '../Asides/CreateAside';
import TableView from '../TableView/TableView';
import { IconMap } from '../../config/IconMap';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function ProductGrid({ triggerNotification }) {
    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isCreateProductDialogOpen, setIsProductDialogOpen] = useState(false);

    // Check localStorage for user preference or default to 'tile'
    const [viewType, setViewType] = useState(() => {
        return localStorage.getItem('viewType') || 'tile';
    });

    const authToken = Cookies.get('auth_token');

    const getProducts = async () => {
        setIsLoading(true);
        try {
            const response = await fetch('/api/products', {
                method: 'GET',
                headers: {
                    'Authorization': `Token ${authToken}`,
                },
            });

            if (response.ok) {
                const data = await response.json();
                setProducts(data);
            } else {
                console.error('Request failed with status:', response.status);
                const errorData = await response.json();
                console.error('Error response:', errorData);
            }

            setIsLoading(false);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleProductCreate = async () => {
        await getProducts();
        triggerNotification();
    };

    useEffect(() => {
        getProducts();
    }, [authToken]);

    const closeProductDialog = () => {
        setIsProductDialogOpen(false);
    };

    const toggleView = () => {
        const newViewType = viewType === 'tile' ? 'table' : 'tile';
        setViewType(newViewType); // Update state
        localStorage.setItem('viewType', newViewType); // Store user preference in localStorage
    };

    const tableContext = {
        ACTION: 'Create product',
        REF: 'product',
        DESCRIPTION: 'Test',
        DELETE_ENABLED: true,
        DELETE_MESSAGING: {
            title: 'Delete Product',
            contextMsg: 'Are you sure you want to delete this product?',
        },
        ERRORS: {
            message: 'No products found.',
        },
        SCHEMA: {
            column_values: [{ name: 'Name', description: 'Description', price: 'Price' }],
        },
    };

    //Detect dark mode for editor
    const [isDarkMode] = useDarkMode();

    return (
        <div>
            <div className="flex justify-between items-center">
                <h1 className="text-base font-semibold text-gray-900 dark:text-white/75">Products</h1>
                <button onClick={toggleView} className="flex items-center space-x-2 p-0 border-none bg-transparent">
                    {viewType === 'tile' ? (
                        React.createElement(IconMap['TableCellsIcon'], {
                            className: 'h-6 w-6 text-gray-500 hover:text-indigo-600 dark:hover:text-indigo-300',
                            'aria-hidden': 'true',
                        })
                    ) : (
                        React.createElement(IconMap['Squares2X2Icon'], {
                            className: 'h-6 w-6 text-gray-500 hover:text-indigo-600 dark:hover:text-indigo-300',
                            'aria-hidden': 'true',
                        })
                    )}
                </button>
            </div>

            {isLoading ? (
                <div className="flex">
                    <Skeleton className="my-2 rounded-lg" count={1} height={144} width={378}  baseColor={isDarkMode ? "rgb(39 39 42)" : "rgb(229 231 235)"} highlightColor={isDarkMode ? "rgb(63 63 70)" : "rgb(243 244 246)"} />
                </div>
            ) : viewType === 'tile' ? (
                <>
                    {/* Use the new grid classes here */}
                    <ul
                        role="list"
                        className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8 mt-4"
                    >
                        {products.map((product) => (
                            <li key={product.uid} className="relative">
                                <Link
                                    to={`/m/product/edit/${product.uid}`}
                                    className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100"
                                >
                                    <img
                                        alt={product.title} // Default to product title if no color attribute
                                        src={product.images?.[0]?.image_url } // Safe access using optional chaining and fallback to placeholder
                                        className="pointer-events-none object-cover group-hover:opacity-75"
                                    />
                                    <button type="button" className="absolute inset-0 focus:outline-none">
                                        <span className="sr-only">View details for {product.title}</span>
                                    </button>
                                </Link>
                                <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900 dark:text-indigo-400">
                                    {product.title}
                                </p>
                                <p className="pointer-events-none block text-sm font-medium text-gray-500 dark:text-white/75">
                                    {product.variants?.[0]?.price || 'No Price'}
                                </p>
                            </li>
                        ))}
                        <button
                            id="create-new"
                            type="button"
                            onClick={() => setIsProductDialogOpen(true)}
                            className="hover:border-blue-500 hover:border-solid hover:text-blue-500 dark:hover:border-indigo-400 dark:hover:text-indigo-400 group w-full flex flex-col items-center justify-center rounded-md border-2 border-dashed border-slate-300 text-sm leading-6 text-slate-900 dark:text-white/75 font-medium py-3 h-full"
                            style={{ minHeight: '8rem' }}
                        >
                            <svg
                                className="group-hover:text-blue-500 group-hover:text-indigo-400 mb-1 text-slate-400"
                                width="20"
                                height="20"
                                fill="currentColor"
                                aria-hidden="true"
                            >
                                <path d="M10 5a1 1 0 0 1 1 1v3h3a1 1 0 1 1 0 2h-3v3a1 1 0 1 1-2 0v-3H6a1 1 0 1 1 0-2h3V6a1 1 0 0 1 1-1Z" />
                            </svg>
                            Create product
                        </button>
                    </ul>
                </>
            ) : (
                // Table View - Reuse the TableView component
                <TableView
                    context={tableContext}
                    data={products}
                    isLoading={isLoading}
                    onAddButtonClick={() => setIsProductDialogOpen(true)}
                    onDeleteSuccess={(itemId) => setProducts(products.filter((product) => product.uid !== itemId))}
                />
            )}

            {isCreateProductDialogOpen && (
                <CreateAside
                    type="product"
                    onClose={closeProductDialog}
                    onCreate={handleProductCreate}
                    isDialogOpen={isCreateProductDialogOpen}
                />
            )}
        </div>
    );
}
