import React, { useState, useEffect, useRef } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { IconMap } from '../../config/IconMap';
import { useParams } from 'react-router-dom';

// Additional imports for API calls
import axios from 'axios';
import Cookies from 'js-cookie';

const Tooltip = ({ 
    appConfig, 
    onUpdateAppConfig, 
    onAddItem, 
    onDeleteItem, 
    position, 
    onPositionChange,
    dimensions,
    onHeightChange,  // Add this prop
    onPreviewNode  // Add this prop
}) => {
    const [editableData, setEditableData] = useState({});
    const [apiOptions, setApiOptions] = useState([]);
    const [editingItemId, setEditingItemId] = useState({ parentId: null, childId: null, selectedOption: null });

    const [isExternalUrl, setIsExternalUrl] = useState(false);
    const [externalUrl, setExternalUrl] = useState('');
    const [externalName, setExternalName] = useState('');
    const [externalDescription, setExternalDescription] = useState('');

    const [selectedAsset, setSelectedAsset] = useState(null);

    const [isDragging, setIsDragging] = useState(false);
    const [dragStart, setDragStart] = useState({ x: 0, y: 0 });

    const handleMouseDown = (e) => {
        setIsDragging(true);
        setDragStart({
            x: e.clientX - position.x,
            y: e.clientY - position.y
        });
    };

    const handleMouseMove = (e) => {
        if (isDragging) {
            const reactFlowBounds = document.querySelector('.react-flow').getBoundingClientRect();

            const newX = Math.max(
                0,
                Math.min(
                    e.clientX - dragStart.x,
                    reactFlowBounds.width - 400 // Adjust based on tooltip width
                )
            );

            const newY = Math.max(
                0,
                Math.min(
                    e.clientY - dragStart.y,
                    reactFlowBounds.height - 300 // Adjust based on approx height
                )
            );

            onPositionChange({ x: newX, y: newY });
        }
    };

    useEffect(() => {
        if (isDragging) {
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', () => setIsDragging(false));
        }
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', () => setIsDragging(false));
        };
    }, [isDragging, dragStart]);

    // Updated toggleExternalUrl function
    const toggleExternalUrl = (checked) => {
        setIsExternalUrl(checked);
        // Don't reset editing state here, as we want to keep the edit state visible
    };

    const fetchOptionsFromApi = async (authToken, uid) => {
        try {
            const response = await axios.get('/api/get-assets', {
                headers: {
                    'Authorization': `Token ${authToken}`,
                },
            });

            // Assuming response.data is an array of assets
            const assets = response.data;

            // Separating forms and pages based on asset_type
            const forms = assets.filter(asset => asset.asset_type === 'Form');
            const pages = assets.filter(asset => asset.asset_type === 'Page');
            const products = assets.filter(asset => asset.asset_type === 'Product');
            const apps = assets.filter(asset => asset.asset_type === 'App' && asset.uid !== uid);

            // Combine forms, pages, apps, and products into a single array
            const combinedOptions = [...forms, ...pages, ...apps, ...products].map((option, index) => ({
                ...option,
                id: index,
            }));

            setApiOptions(combinedOptions);
        } catch (error) {
            console.error('Error fetching options from API:', error);
        }
    };

    const authToken = Cookies.get('auth_token');
    const uid = useParams();
    useEffect(() => {
        fetchOptionsFromApi(authToken, uid.uid);
    }, [authToken]);

    useEffect(() => {
        const newEditableData = appConfig.reduce((acc, item) => {
            acc[item.id] = editableData[item.id] || {
                title: item.display_title || '',
                type: item.type || 'action'
            };
            return acc;
        }, {});
        setEditableData(newEditableData);
    }, [appConfig]);

    // Function to handle the title change
    const handleTitleChange = (id, newTitle) => {
        setEditableData(prevData => ({
            ...prevData,
            [id]: { ...prevData[id], title: newTitle }
        }));
    };

    // Function to handle the type change
    const handleTypeChange = (id, newType) => {
        setEditableData(prevData => ({
            ...prevData,
            [id]: { ...prevData[id], type: newType }
        }));
        // Fetch options if the type is 'action' or 'gallery'
        if (newType === 'action' || newType === 'gallery') {
            fetchOptionsFromApi();
        }
    };

    // Function to update the actual appConfig when the user leaves the input field (onBlur)
    const updateItemInConfig = (id) => {
        onUpdateAppConfig(
            appConfig.map(item => {
                if (item.id === id) {
                    return { ...item, display_title: editableData[id].title, type: editableData[id].type };
                }
                return item;
            })
        );
    };

    // Function to delete a child item from an item's schema property
    const deleteChildItem = (parentId, childId) => {
        const updatedAppConfig = appConfig.map(item => {
            if (item.id === parentId) {
                // Filter out the child item from the schema subarray
                const updatedSchema = item.schema.filter(childItem => childItem.id !== childId);
                return { ...item, schema: updatedSchema };
            }
            return item;
        });
        onUpdateAppConfig(updatedAppConfig);
    };

    // Simplify onDragEnd to use exact IDs
    const onDragEnd = (result) => {
        const { source, destination } = result;
        
        if (!destination) return;

        // Get the parent section ID (now it's just the droppableId)
        const sectionId = source.droppableId;
        
        const updatedAppConfig = appConfig.map(section => {
            if (section.id === sectionId) {
                const newSchema = Array.from(section.schema || []);
                const [removed] = newSchema.splice(source.index, 1);
                newSchema.splice(destination.index, 0, removed);
                return { ...section, schema: newSchema };
            }
            return section;
        });

        onUpdateAppConfig(updatedAppConfig);
    };

    // Add ref for content measurement - move before any returns
    const contentRef = useRef(null);
    
    // Add useEffect to monitor content height - move before any returns
    useEffect(() => {
        if (contentRef.current) {
            const contentHeight = contentRef.current.scrollHeight;
            onHeightChange(contentHeight + 50); // Add padding
        }
    }, [appConfig, editingItemId, onHeightChange]);

    if (!Array.isArray(appConfig)) {
        return <div>Loading...</div>;
    }

    // Function to handle selecting an option from the dropdown
    const selectSchemaItem = (parentId, selectedOptionId) => {
        const selectedOption = apiOptions.find(option => option.id.toString() === selectedOptionId);
        if (selectedOption) {
            setEditingItemId(prev => ({ ...prev, parentId, selectedOption }));
        }
    };

    const addNewItem = () => {
        const newItemId = `temp-item-${new Date().getTime()}`;
        onAddItem(newItemId);
        setEditingItemId({ parentId: newItemId, childId: null });
    };

    const renderSchemaItem = (childItem, parentId) => {
        const isExternalLink = childItem.option === 'external';
        const canPreview = ['App'].includes(childItem.asset_type); // Only allow App preview for now

        return (
            <div className="schema-item p-2 my-2 rounded flex justify-between items-center rounded-md bg-white dark:bg-zinc-800 px-2.5 py-1.5 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/20 hover:bg-gray-50 dark:hover:bg-zinc-900 disabled:bg-indigo-400">
                <div className="pl-1">
                    <p className="text-sm font-medium leading-6 text-gray-900 dark:text-white/75">{childItem.name}</p>
                    <p className="text-sm font-medium leading-6 text-gray-900 dark:text-white/75">{childItem.description}</p>
                    {isExternalLink && (
                        <p className="text-sm leading-6 text-gray-500">{childItem.to}</p>
                    )}
                </div>
                <div className="flex items-center gap-2">
                    {canPreview && (
                        <button 
                            onClick={() => onPreviewNode(childItem)} 
                            className="preview-button mr-2 rounded-md border-0 py-1 px-1 text-gray-900 dark:text-white/75 bg-white dark:bg-zinc-800 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 hover:bg-gray-50 dark:hover:bg-zinc-900"
                        >
                            {React.createElement(IconMap[childItem.isPreviewVisible ? 'EyeSlashIcon' : 'EyeIcon'], { 
                                className: 'h-6 w-6', 
                                'aria-hidden': 'true' 
                            })}
                        </button>
                    )}
                    <button onClick={() => deleteChildItem(parentId, childItem.id)} className="delete-child-item-button mr-2 rounded-md border-0 py-1 px-1 text-gray-900 dark:text-white/75 bg-white dark:bg-zinc-800 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 hover:bg-gray-50 dark:hover:bg-zinc-900">
                        {React.createElement(IconMap['XMarkIcon'], { 
                            className: 'h-6 w-6', 
                            'aria-hidden': 'true' 
                        })}
                    </button>
                </div>
            </div>
        );
    };

    const saveNewSchemaItem = (parentId) => {
        let newSchemaItem;
        if (isExternalUrl) {
            newSchemaItem = {
                id: `child-${new Date().getTime()}`,
                name: externalName,
                description: externalDescription,
                to: externalUrl,
                option: 'external'
            };
        } else if (editingItemId.selectedOption && editingItemId.selectedOption.description) {
            // Determine prefix and create schema item based on asset type
            const isProduct = editingItemId.selectedOption.asset_type === 'Product';
            const prefix = editingItemId.selectedOption.asset_type === 'Page' ? '/a/p/' :
                editingItemId.selectedOption.asset_type === 'Form' ? '/a/f/' : '/a/';

            newSchemaItem = {
                id: `child-${new Date().getTime()}`,
                name: editingItemId.selectedOption.title,
                description: editingItemId.selectedOption.description,
                to: isProduct ? editingItemId.selectedOption.uid : prefix + editingItemId.selectedOption.uid,
                option: isProduct ? 'gallery' : editingItemId.selectedOption.asset_type,
                asset_type: editingItemId.selectedOption.asset_type
            };

            // Add background image for products
            if (isProduct && editingItemId.selectedOption.images?.[0]) {
                newSchemaItem.background_image_url = editingItemId.selectedOption.images[0].image_url;
            }
        }

        const updatedAppConfig = appConfig.map(item => {
            if (item.id === parentId) {
                return { ...item, schema: [...item.schema, newSchemaItem] };
            }
            return item;
        });

        onUpdateAppConfig(updatedAppConfig);
        setEditingItemId({ parentId: null, childId: null, selectedOption: null });
        setIsExternalUrl(false);
        setExternalUrl('');
        setExternalName('');
        setExternalDescription('');
    };

    const saveEditedItem = (parentId, childId, selectedOption) => {
        // Logic for saving a new child item
        if (childId === null) {
            const newChild = {
                id: `child-${new Date().getTime()}`,
                name: selectedOption.name,
                description: selectedOption.description,
                // Include other properties from selectedOption if necessary
            };
            const updatedAppConfig = appConfig.map(item => {
                if (item.id === parentId) {
                    return { ...item, schema: [...item.schema, newChild] };
                }
                return item;
            });
            onUpdateAppConfig(updatedAppConfig);
        } else {
            // Logic for updating an existing child item
            const updatedAppConfig = appConfig.map(item => {
                if (item.id === parentId) {
                    const updatedSchema = item.schema.map(child => {
                        if (child.id === childId) {
                            return { ...child, ...selectedOption };
                        }
                        return child;
                    });
                    return { ...item, schema: updatedSchema };
                }
                return item;
            });
            onUpdateAppConfig(updatedAppConfig);
        }

        // Reset the editing state
        setEditingItemId(null);
    };

    // Add a function to filter options based on section type
    const getFilteredOptions = (sectionType) => {
        if (!apiOptions) return [];
        
        if (sectionType === 'gallery') {
            return apiOptions.filter(option => option.asset_type === 'Product');
        } else if (sectionType === 'action') {
            return apiOptions.filter(option => 
                ['Page', 'Form', 'App'].includes(option.asset_type)
            );
        }
        return [];
    };

    return (
        <div style={{
                width: `${dimensions.width}px`,
                height: `${dimensions.height}px`,
                display: 'flex',
                flexDirection: 'column'
            }}>
            <div
                className="h-6 bg-gray-100 dark:bg-zinc-800 rounded-t-lg border border-gray-300 dark:border-white/10 cursor-move flex items-center justify-center flex-shrink-0"
                onMouseDown={handleMouseDown}
            >
                <div className="flex gap-1">
                    {[...Array(3)].map((_, i) => (
                        <div key={i} className="w-1 h-1 rounded-full bg-gray-400" />
                    ))}
                </div>
            </div>

            {/* Wrap content in a scrollable container */}
            <div 
                ref={contentRef}
                className="tooltip bg-gray-200 dark:bg-zinc-900 rounded-b-lg border border-gray-300 dark:border-white/10 p-2 overflow-y-auto flex-grow"
            >
                <DragDropContext onDragEnd={onDragEnd}>
                    {appConfig.map((item) => {
                        if (!editableData[item.id]) return null;

                        return (
                            <div key={item.id} className="config-item p-1 mb-2 bg-gray-50 dark:bg-zinc-800 rounded-lg shadow">
                                {/* Draggable title bar */}
                                <div className="flex items-center p-2 w-full gap-x-2">
                                    <input
                                        type="text"
                                        className="flex-1 min-w-0 rounded-md border-0 py-1.5 text-gray-900 dark:text-white/75 dark:bg-zinc-800 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:placeholder:text-white/75 dark:ring-white/75"
                                        value={editableData[item.id].title}
                                        onChange={(e) => handleTitleChange(item.id, e.target.value)}
                                        onBlur={() => updateItemInConfig(item.id)}
                                    />
                                    <select
                                        className="w-32 bg-gray-50 dark:bg-zinc-800 dark:text-white/75 rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        value={editableData[item.id].type}
                                        onChange={(e) => handleTypeChange(item.id, e.target.value)}
                                        onBlur={() => updateItemInConfig(item.id)}
                                    >
                                        <option value="action">Navigation</option>
                                        <option value="gallery">Gallery</option>
                                    </select>
                                    <button onClick={() => onDeleteItem(item.id)} className="delete-item-button ml-2 rounded-md border-0 py-1 px-1 text-gray-900 dark:text-white/75 dark:hover:bg-zinc-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 hover:bg-gray-50 ">
                                        {React.createElement(IconMap['XMarkIcon'], { className: 'h-6 w-6', 'aria-hidden': 'true' })}
                                    </button>
                                </div>

                                {/* Simplified Droppable - use the item.id directly */}
                                <Droppable droppableId={item.id}>
                                    {(provided) => ( // Remove snapshot if not needed
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                            className="child-items mx-2 my-2"
                                        >
                                            {(item.schema || []).map((childItem, childIndex) => (
                                                <Draggable
                                                    key={childItem.id}
                                                    draggableId={childItem.id}
                                                    index={childIndex}
                                                >
                                                    {(provided) => ( // Remove snapshot if not needed
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            {renderSchemaItem(childItem, item.id)}
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>

                                {editingItemId && editingItemId.parentId === item.id && !editingItemId.childId ? (
                                    <div className="new-schema-edit-state m-2 mt-4 bg-gray-100 dark:bg-zinc-900 p-4 rounded-lg">

                                        {/* Asset selection dropdown */}
                                        {!isExternalUrl && (
                                            <div>
                                                <label htmlFor="select-asset" className='block text-sm mb-2 font-medium leading-6 text-gray-900 dark:text-white/75'>
                                                    {editableData[item.id].type === 'gallery' ? 'Available products' : 'Available assets'}
                                                </label>
                                                <select
                                                    id="select-asset"
                                                    onChange={(e) => selectSchemaItem(item.id, e.target.value)}
                                                    disabled={isExternalUrl}
                                                    className="mb-1.5 bg-gray-50 dark:bg-zinc-900 rounded-md w-full border-0 py-1.5 pl-3 pr-10 text-gray-900 dark:text-white/75 ring-1 ring-inset ring-gray-300 dark:ring-white/40 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                >
                                                    <option value="" disabled selected>
                                                        {editableData[item.id].type === 'gallery' 
                                                            ? 'Select a product' 
                                                            : 'Select an asset'}
                                                    </option>
                                                    {getFilteredOptions(editableData[item.id].type).map((option, index) => (
                                                        <option key={index} value={option.id.toString()}>
                                                            {option.title} ({option.asset_type})
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        )}

                                        {/* Description input for selected asset */}
                                        {!isExternalUrl && editingItemId.selectedOption && (
                                            <div className="my-2">
                                                <label htmlFor="asset-description" className='block text-sm mb-2 font-medium leading-6 text-gray-900 dark:text-white/75'>
                                                    Description
                                                </label>
                                                <input
                                                    id="asset-description"
                                                    type="text"
                                                    placeholder="Enter description for the asset"
                                                    value={editingItemId.selectedOption.description || ''}
                                                    onChange={(e) => setEditingItemId(prev => ({ ...prev, selectedOption: { ...prev.selectedOption, description: e.target.value } }))}
                                                    required
                                                    className="mb-2 rounded-md border-0 w-full py-1.5 text-gray-900 dark:text-white/75 dark:bg-zinc-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/40 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                />
                                            </div>
                                        )}

                                        {/* Checkbox for external URL */}
                                        <div>
                                            <input
                                                id="select-external"
                                                type="checkbox"
                                                checked={isExternalUrl}
                                                onChange={(e) => toggleExternalUrl(e.target.checked)}
                                                className='mr-2 dark:bg-zinc-900 checked:dark:bg-zinc-700 checked:dark:ring-1 checked:dark:ring-white/75'
                                            />
                                            <label htmlFor="select-external" className='text-sm font-medium leading-6 text-gray-900 dark:text-white/75'>
                                                Use external URL instead
                                            </label>
                                        </div>

                                        {isExternalUrl && (
                                            <>
                                                <div className="mb-2 mt-4">
                                                    <label htmlFor="enter-title" className='block text-sm mb-2 font-medium leading-6 text-gray-900 dark:text-white/75'>
                                                        Display title
                                                    </label>
                                                    <input
                                                        id="enter-title"
                                                        type="text"
                                                        placeholder="Enter display title"
                                                        required
                                                        value={externalName}
                                                        onChange={(e) => setExternalName(e.target.value)}
                                                        className="mb-2 rounded-md border-0 w-full py-1.5 text-gray-900 dark:text-white/75 dark:bg-zinc-800 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:placeholder:text-white/75 dark:ring-white/10" // Add styling classes
                                                    />
                                                </div>
                                                <div className="my-2">
                                                    <label htmlFor="enter-description" className='block text-sm mb-2 font-medium leading-6 text-gray-900 dark:text-white/75'>
                                                        Display description
                                                    </label>
                                                    <input
                                                        id="enter-description"
                                                        type="text"
                                                        required
                                                        placeholder="Enter description"
                                                        value={externalDescription}
                                                        onChange={(e) => setExternalDescription(e.target.value)}
                                                        className="mb-2 rounded-md border-0 w-full py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:text-white/75 dark:bg-zinc-800 dark:placeholder:text-white/75 dark:ring-white/10" // Add styling classes
                                                    />
                                                </div>
                                                <div className="my-2">
                                                    <label htmlFor="enter-url" className='block text-sm mb-2 font-medium leading-6 text-gray-900 dark:text-white/75'>
                                                        External URL
                                                    </label>
                                                    <input
                                                        id="enter-url"
                                                        type="url"
                                                        required
                                                        placeholder="Enter external URL"
                                                        value={externalUrl}
                                                        onChange={(e) => setExternalUrl(e.target.value)}
                                                        className="mb-2 rounded-md border-0 w-full py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:text-white/75 dark:bg-zinc-800 dark:placeholder:text-white/75 dark:ring-white/10" // Add styling classes
                                                    />
                                                </div>
                                            </>
                                        )}

                                        <button
                                            onClick={() => saveNewSchemaItem(item.id)}
                                            disabled={(isExternalUrl && (!externalName || !externalDescription || !externalUrl)) ||
                                                (!isExternalUrl && editingItemId.selectedOption && !editingItemId.selectedOption.description)}
                                            className="add-item-button mt-4 w-full rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-0 hover:bg-indigo-700 disabled:bg-indigo-400"
                                        >
                                            Save
                                        </button>
                                    </div>
                                ) : (
                                    // Button to add a new schema item
                                    <button
                                        onClick={() => setEditingItemId({ parentId: item.id, childId: null })}
                                        className="add-item-button mt-2 rounded-md bg-white dark:bg-zinc-800 p-2 m-2 text-sm font-semibold text-gray-900 dark:text-indigo-300 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-indigo-400 hover:bg-gray-50 disabled:bg-indigo-400 dark:hover:bg-zinc-900"
                                    >
                                        Create action
                                    </button>
                                )}

                            </div>
                        );
                    })}
                    {/* Button to add new item */}
                    <div className="tooltip-actions">
                        <button onClick={addNewItem} className="add-item-button mt-4 w-full rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-0 hover:bg-indigo-700 disabled:bg-indigo-400">
                            Create section
                        </button>
                    </div>
                </DragDropContext>
            </div>
            <style>
                {`
                    .react-resizable {
                        position: relative;
                        background-color: white;
                    }
                    
                    .react-resizable-handle {
                        position: absolute;
                        width: 20px;
                        height: 20px;
                        bottom: 0;
                        right: 0;
                        background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3J1bmQtY29sb3I6I2ZmZmZmZjAwIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjZweCIgaGVpZ2h0PSI2cHgiPjxnIG9wYWNpdHk9IjAuMzAyIj48cGF0aCBkPSJNIDYgNiBMIDAgNiBMIDAgNC4yIEwgNCA0LjIgTCA0LjIgNC4yIEwgNC4yIDAgTCA2IDAgTCA2IDYgTCA2IDYgWiIgZmlsbD0iIzAwMDAwMCIvPjwvZz48L3N2Zz4=');
                        background-position: bottom right;
                        padding: 0 3px 3px 0;
                        background-repeat: no-repeat;
                        background-origin: content-box;
                        box-sizing: border-box;
                        cursor: se-resize;
                    }

                    .react-resizable-handle:hover {
                        background-color: rgba(0, 0, 0, 0.05);
                        border-radius: 0 0 4px 0;
                    }
                `}
            </style>
        </div>
    );
};

export default Tooltip;