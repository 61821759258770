import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom';

import Cookies from 'js-cookie';
import { IconMap } from '../config/IconMap';
import { QRCodeSVG } from 'qrcode.react';

/* Components */
import StoryBoard from '../components/Canvas/Storyboard';
import AppDetailEditView from '../components/Apps/AppDetailEditView';
import StyleGuide from '../components/StyleGuides/StyleGuide';

export default function AppEditor() {

    // States
    const [editedAppName, setEditedAppName] = useState('');
    const [editedAppDescription, setEditedAppDescription] = useState('');
    const [editedAppConfig, setEditedAppConfig] = useState([]);
    const [imageFile, setImageFile] = useState(null); // State to hold the uploaded image
    const [backgroundImageUrl, setBackgroundImageUrl] = useState(null); // Add this state

    const [editedStyleGuide, setStyleGuide] = useState({});
    const [changesMade, setChangesMade] = useState(false);
    const { uid } = useParams();

    let baseURL;
    if (window.location.hostname === "localhost") { baseURL = "http://localhost:3000"; } else { baseURL = process.env.REACT_APP_URL; }
    const previewURL = `${baseURL}/a/${uid}`;

    // State to manage the visibility of the preview QR code
    const [isPreviewAsideVisible, setIsPreviewAsideVisible] = useState(false);
    const togglePreviewAside = () => {
        setIsPreviewAsideVisible(!isPreviewAsideVisible);
    };

    // State to manage the visibility of the title/description elements
    const [isMetaAsideVisible, setIsMetaAsideVisible] = useState(true);
    const toggleMetaAside = () => {
        setIsMetaAsideVisible(!isMetaAsideVisible);
    };

    // State to manage the visibility of the title/description elements
    const [isControlsAsideVisible, setIsControlsAsideVisible] = useState(false);
    const toggleControlsAside = () => {
        setIsControlsAsideVisible(!isControlsAsideVisible);
    };

    useEffect(() => {
        const authToken = Cookies.get('auth_token');
        async function fetchAppInfo() {
            try {
                if (!uid || !authToken) {
                    console.error('There was an issue with this request.');
                    return;
                }
                const response = await fetch(`/api/get-app/${uid}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Token ${authToken}`,
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    console.log('API Response:', {
                        title: data.title,
                        description: data.description,
                        background_image_url: data.background_image_url,
                        full_response: data
                    });
                    
                    setEditedAppName(data.title);
                    setEditedAppDescription(data.description);
                    setEditedAppConfig(data.app_config || []);
                    setStyleGuide(data.style_guide);
                    setBackgroundImageUrl(data.background_image_url);
                    
                    console.log('State after update:', {
                        backgroundImageUrl: data.background_image_url,
                        editedAppName: data.title,
                        editedAppDescription: data.description
                    });
                } else {
                    console.error('Request failed with status:', response.status);
                    const errorData = await response.json();
                    console.error('Error response:', errorData);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        }
        fetchAppInfo();
    }, [uid]);

    useEffect(() => {
        console.log('AppEditor state - backgroundImageUrl:', backgroundImageUrl);
    }, [backgroundImageUrl]);

    // Callback function to update edited app name
    const handleAppNameChange = (newAppName) => {
        setEditedAppName(newAppName);
        setChangesMade(true);
    };

    // Callback function to update edited app description
    const handleAppDescriptionChange = (newAppDescription) => {
        setEditedAppDescription(newAppDescription);
        setChangesMade(true);
    };

    // Change styles and register change for publish
    const handleStyleGuideChange = (newStyleGuide) => {
        setStyleGuide(newStyleGuide);
        setChangesMade(true); 
    };

    // The image has changed prior to publish
    const handleImageFileChange = (newImageFile) => {
        setImageFile(newImageFile);
        setChangesMade(true);
    };

    //pass to the editor
    let app_metadata = {
        title: editedAppName,
        description: editedAppDescription,
        app_config: editedAppConfig,
        style_guide: editedStyleGuide,
        background_image: imageFile,
        background_image_url: backgroundImageUrl, // Add this line
    }

    // Function to handle the publish action
    const handlePublish = () => {
        // Send a request to your API to update the app with the new title and description
        const apiUrl = `/api/update-app/${uid}/`;
        const authToken = Cookies.get('auth_token');
        
        const requestBody = {
            display_title: editedAppName,
            display_description: editedAppDescription,
            app_config: editedAppConfig,
            style_guide: editedStyleGuide.uid,
            background_image: imageFile,
            background_image_url: backgroundImageUrl, // Add this line
        };

        fetch(apiUrl, {
            method: 'PUT',
            headers: {
                'Authorization': `Token ${authToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        })
            .then((response) => {
                if (response.ok) {
                    setChangesMade(false); // Reset changes status after successful update
                } else {
                    console.error('Failed to update app');
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    // Function to handle updates to appConfig, such as reordering or title changes
    const handleAppConfigUpdate = (newConfig) => {
        setEditedAppConfig(newConfig);
        setChangesMade(true); // Indicate that there are unsaved changes
    };

    // Function to add a new item to the appConfig
    const addNewConfigItem = () => {
        const newItem = {
            id: `new-${Date.now()}`,
            display_title: 'New section',
            type: 'action',
            schema: []
        };
        setEditedAppConfig(prevConfig => [...prevConfig, newItem]);
        setChangesMade(true);
    };

    // Function to delete an item from the appConfig
    const deleteConfigItem = (itemId) => {
        const filteredItems = editedAppConfig.filter(item => item.id !== itemId);
        setEditedAppConfig(filteredItems);
    };

    return (
        <>
            <main className="lg:pl-72">
                <div className="xl:pr-96">
                    <div>
                        <StoryBoard
                            app_metadata={app_metadata}
                            appConfig={editedAppConfig}
                            onAppConfigUpdate={handleAppConfigUpdate}
                            onAddItem={addNewConfigItem}
                            onDeleteItem={deleteConfigItem}
                        />
                    </div>
                </div>
            </main>
            <aside className="fixed inset-y-0 right-0 hidden w-96 overflow-y-auto border-l bg-white dark:bg-zinc-800 border-gray-200 xl:block">
                <div className="mt-auto h-1/3 border-t border-gray-300">
                    <button onClick={togglePreviewAside} className={`flex w-full gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50 ${isPreviewAsideVisible ? 'bg-gray-50' : ''}`}>
                        {
                            isPreviewAsideVisible ? (
                                <>
                                    {React.createElement(
                                        IconMap['MinusIcon'],
                                        {
                                            className: 'h-5 w-5 text-gray-500',
                                            'aria-hidden': 'true',
                                        }
                                    )}
                                    Preview
                                </>
                            ) : (
                                <>
                                    {React.createElement(
                                        IconMap['PlusIcon'],
                                        {
                                            className: 'h-5 w-5 text-gray-500',
                                            'aria-hidden': 'true',
                                        }
                                    )}
                                    Preview
                                </>
                            )
                        }
                        <span className="sr-only">Preview</span><span aria-hidden="true"></span>
                    </button>
                    <div className={`flex items-start flex-wrap bg-gray-50 px-6 py-6 ${isPreviewAsideVisible ? 'block' : 'hidden'}`}>
                        <div className="w-1/2">
                            <QRCodeSVG value={previewURL} />
                        </div>
                        <div className="w-1/2">
                            <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                                App URL
                            </label>
                            <p className="mt-3 text-sm leading-6 text-gray-600">This is the unique URL assigned to your app.</p>
                            <div className="mt-2">
                                <div>
                                    <Link target="_blank"
                                        to={previewURL}
                                        className="group flex items-center space-x-2.5 text-sm font-medium text-indigo-600 hover:text-indigo-900">
                                        {React.createElement(
                                            IconMap['ArrowTopRightOnSquareIcon'], { className: 'h-5 w-5 text-indigo-500 group-hover:text-indigo-900', 'aria-hidden': 'true' }
                                        )}
                                        <span>Visit URL</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button onClick={toggleMetaAside} className="flex w-full gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50">
                        {
                            isMetaAsideVisible ? (
                                <>
                                    {React.createElement(
                                        IconMap['MinusIcon'],
                                        {
                                            className: 'h-5 w-5 text-gray-500',
                                            'aria-hidden': 'true',
                                        }
                                    )}
                                    Configuration
                                </>
                            ) : (
                                <>
                                    {React.createElement(
                                        IconMap['PlusIcon'],
                                        {
                                            className: 'h-5 w-5 text-gray-500',
                                            'aria-hidden': 'true',
                                        }
                                    )}
                                    Configuration
                                </>
                            )
                        }
                        <span className="sr-only">Set configuration for this app</span><span aria-hidden="true"></span>
                    </button>
                    <div className={`col-span-full ${isMetaAsideVisible ? 'block' : 'hidden'}`}>
                        <AppDetailEditView
                            display_title={editedAppName}
                            display_description={editedAppDescription}
                            background_image_url={app_metadata.background_image_url} // Change this line
                            onAppNameChange={handleAppNameChange}
                            onAppDescriptionChange={handleAppDescriptionChange}
                            onImageFileChange={handleImageFileChange}
                        />
                        <StyleGuide
                            currentStyleGuide={editedStyleGuide}
                            onStyleGuideChange={handleStyleGuideChange}
                        />
                    </div>
                    <button onClick={toggleControlsAside} className={`flex w-full gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50 ${isControlsAsideVisible ? 'bg-gray-50' : ''}`}>
                        {
                            isControlsAsideVisible ? (
                                <>
                                    {React.createElement(
                                        IconMap['MinusIcon'],
                                        {
                                            className: 'h-5 w-5 text-gray-500',
                                            'aria-hidden': 'true',
                                        }
                                    )}
                                    Settings
                                </>
                            ) : (
                                <>
                                    {React.createElement(
                                        IconMap['PlusIcon'],
                                        {
                                            className: 'h-5 w-5 text-gray-500',
                                            'aria-hidden': 'true',
                                        }
                                    )}
                                    Settings
                                </>
                            )
                        }
                        <span className="sr-only">Settings for this app</span><span aria-hidden="true"></span>
                    </button>
                    <div className={`px-2 py-2 ${isControlsAsideVisible ? 'block' : 'hidden'}`}>
                        <div className='flex items-start justify-between flex-wrap'>
                            <div className="w-1/2 p-1">
                                <dt className="p-2 m-1 text-sm font-medium text-gray-900">Disable app</dt>
                            </div>
                            <div className="w-1/2 flex justify-end">
                                <button className="w-full m-2 ml-2 rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500">Disable</button>
                            </div>
                        </div>
                        <div className='flex items-start justify-between flex-wrap '>
                            <div className="w-1/2 p-1">
                                <dt className="p-2 m-1 text-sm font-medium text-gray-900">Delete app</dt>
                            </div>
                            <div className="w-1/2 flex justify-end">
                                <button className="w-full m-2 ml-2 rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm disabled:bg-red-200 hover:bg-red-500" disabled={true}>Delete</button>
                            </div>
                        </div>
                    </div>
                    <div className="w-full">
                        <Link id="publish" onClick={handlePublish} disabled={!changesMade} className={`flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 ${changesMade ? 'bg-green-500 text-white hover:bg-green-600 hover:text-white cursor-pointer' : 'bg-gray-300 text-gray-600 cursor-not-allowed'}`}>
                            <span className="sr-only">Current draft option: </span><span aria-hidden="true">Publish</span>
                        </Link>
                        <Link className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50" href="#">
                            <span className="sr-only">Current draft option: </span><span aria-hidden="true">Discard</span>
                        </Link>
                    </div>
                </div>
            </aside>
        </>
    )
}