import React, { useState } from 'react';

/* Components */
import Apps from '../components/Apps/Apps';
import Notification from '../components/Notifications/Notifications';
import useDarkMode from '../hooks/useDarkMode'; 

export default function Dashboard() {
    const [showNotification, setShowNotification] = useState(false);
    const [darkModeEnabled, handleToggleDarkMode] = useDarkMode(); 

    const triggerNotification = () => {
        setShowNotification(true);
        setTimeout(() => {
            setShowNotification(false); 
        }, 3000); 
    };

    return (
        <>
            <main className="lg:pl-72">
                <div className="xl:pr-96">
                    <div className="px-4 py-6 sm:px-6 lg:px-8 lg:py-6">
                        <Apps triggerNotification={triggerNotification} />
                        <Notification 
                            show={showNotification} 
                            onClose={() => setShowNotification(false)}
                        />
                    </div>
                </div>
            </main>
        </>
    );
}