import { useState, useEffect } from 'react';

const useDarkMode = () => {
    const [darkModeEnabled, setDarkModeEnabled] = useState(false);

    useEffect(() => {
        const savedTheme = localStorage.getItem('shared-theme');
        if (savedTheme === 'dark') {
            document.documentElement.classList.add('dark');
            setDarkModeEnabled(true);
        } else {
            document.documentElement.classList.remove('dark');
            setDarkModeEnabled(false);
        }
    }, []);

    const handleToggleDarkMode = () => {
        setDarkModeEnabled((prev) => {
            const newDarkModeState = !prev;
            
            // Apply or remove the dark class to the root element
            if (newDarkModeState) {
                document.documentElement.classList.add('dark');
                localStorage.setItem('shared-theme', 'dark'); // Persist the preference
            } else {
                document.documentElement.classList.remove('dark');
                localStorage.setItem('shared-theme', 'light');
            }
            
            return newDarkModeState;
        });
    };

    return [darkModeEnabled, handleToggleDarkMode];
};

export default useDarkMode;
