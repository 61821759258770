import React, { useState, useEffect } from 'react';
import { IconMap } from '../../config/IconMap';
import ConfirmationModal from '../ConfirmModal/ConfirmModal';
import Cookies from 'js-cookie';

const ImageUploader = ({ 
  imageFile, 
  setImageFile, 
  accept = "image/*", 
  label = "Upload Image",
  hint = null,
  existingImageUrl = '',
  uid = null // Make uid optional with default value null
}) => {
  const [error, setError] = useState(null);
  const [preview, setPreview] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  useEffect(() => {
    if (imageFile) {
      setPreview(URL.createObjectURL(imageFile));
    } else if (existingImageUrl && existingImageUrl !== '') {
      setPreview(existingImageUrl);
    }
  }, [imageFile, existingImageUrl]);

  const handleImageChange = (e) => {
    const { files } = e.target;

    if (files && files.length > 0) {
      const file = files[0];
      
      // File size check (1MB limit)
      if (file.size > 1024 * 1024) {
        setError('File size exceeds 1MB');
        return;
      }

      // Set the image file and preview URL
      setImageFile(file);
      setPreview(URL.createObjectURL(file)); // Create URL for image preview
      setError(null);
    }
  };

  const handleRemoveImage = () => {
    if (existingImageUrl && uid) {
      // Only show confirmation modal if we're editing an existing app image
      setIsDeleteModalOpen(true);
    } else {
      // For new uploads or non-app contexts, just clear the image
      setImageFile(null);
      setPreview(null);
      setError(null);
    }
  };

  const confirmImageDelete = async () => {
    // Only attempt API call if we have a uid
    if (!uid) {
      setImageFile(null);
      setPreview(null);
      setError(null);
      setIsDeleteModalOpen(false);
      return;
    }

    try {
      const authToken = Cookies.get('auth_token');
      const response = await fetch(`/api/delete-background-image/${uid}/`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Token ${authToken}`,
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        setImageFile(null);
        setPreview(null);
        setError(null);
      } else {
        setError('Failed to delete image');
      }
    } catch (error) {
      setError('Error deleting image');
      console.error('Error:', error);
    }
    setIsDeleteModalOpen(false);
  };

  return (
    <div className="col-span-full">
      <div>
        <label className="block text-sm font-medium leading-6 text-gray-900 dark:text-white/75">
          {label}
        </label>
        {hint && <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">{hint}</p>} 
      </div>
      <div className="sm:col-span-2">
        <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 dark:border-white/25 px-6 py-10">
          <div className="relative text-center">
            {!preview ? (
              <>
                {React.createElement(IconMap['PhotoIcon'], { className: 'mx-auto h-12 w-12 text-gray-300', 'aria-hidden': 'true' })}
                <div className="mt-4 flex text-sm leading-6 text-gray-600">
                  <label
                    htmlFor="image-upload"
                    className="relative cursor-pointer rounded-md bg-white dark:bg-zinc-800 font-semibold text-indigo-600 dark:text-indigo-400 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                  >
                    <span>Upload a file</span>
                    <input
                      id="image-upload"
                      name="image"
                      type="file"
                      accept={accept}
                      className="sr-only"
                      onChange={handleImageChange}
                    />
                  </label>
                  <p className="pl-1 dark:text-white/75">or drag and drop</p>
                </div>
                <p className="text-xs leading-5 text-gray-600 dark:text-white/75">PNG, JPG, GIF up to 1MB</p>
              </>
            ) : (
              <>
              
                <img src={preview} alt="Preview" className="mx-auto h-48 w-48 object-cover rounded-lg" />
                <button
                  type="button"
                  onClick={handleRemoveImage}
                  className="absolute top-4 left-4 text-white font-bold text-2xl rounded-full h-8 w-8 flex items-center justify-center bg-gray-700"
                >
                  {React.createElement(IconMap['XMarkIcon'], { className: 'h-5 w-5 text-white', 'aria-hidden': 'true' })}
                </button>
              </>
            )}
          </div>
        </div>
        {error && <p className="text-xs text-red-600">{error}</p>}
      </div>
      <ConfirmationModal
        title="Delete background image"
        contextMsg="Are you sure you want to delete this background image?"
        open={isDeleteModalOpen}
        onCancel={() => setIsDeleteModalOpen(false)}
        onConfirm={confirmImageDelete}
      />
    </div>
  );
};

export default ImageUploader;
